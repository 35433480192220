import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";

const initialState = {
  globalLoader: true,
  screenWidth: window.innerWidth,
  isMobile: window.innerWidth <= 767,
  apiUrl: 'https://api-test.menuforme.online/api/v1/admin',
};

export const selectCommonState = state => state.common;

const commonSlice = createSlice({
  name: 'common',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
  reducers: {
    setGlobalLoader: (state, action) => {
      state.globalLoader = action.payload;
    },
    setScreenWidth: (state, action) => {
      return {
        ...state,
        screenWidth: action.payload,
        isMobile: action.payload <= 767,
      };
    },
    setApiUrl: (state, action) => {
      state.apiUrl = action.payload;
    },
  },
});

export const {
  setGlobalLoader,
  setScreenWidth,
  setApiUrl,
} = commonSlice.actions;

export default commonSlice.reducer;
