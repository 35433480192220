import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentRoute } from '../../redux/reducers/utils/navReducer';
import { sendRequestCitiesGet } from '../../redux/reducers/utils/settingsReducers/settingsCatalogsReducer';
import { sendRequestKitchens, setSelectKitchen } from '../../redux/reducers/utils/kitchenReducers/kitchenReducer';
import { Container, Page, ScrolledCol, VerticalSpace } from '../../core/styles';

function KitchenModule({children}) {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuth } = useSelector((store) => store.auth);
  const { kitchens, selectKitchen } = useSelector((store) => store.kitchen);
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname === '/kitchens') {
      dispatch(setCurrentRoute('/dashboard'));
      navigate('/dashboard');
    }
  }, [location.pathname]);

  useEffect(() => {
    if (kitchens.length === 1) {
      dispatch(setSelectKitchen(kitchens[0]));
    }

    if (kitchens.length > 1 && selectKitchen === null) {
      dispatch(setSelectKitchen(kitchens[0]));
    }
  }, [kitchens]);

  useEffect(() => {
    if (isAuth) {
      dispatch(sendRequestKitchens({ token: localStorage.getItem('token'), offset: 0, limit: 10000 }));
      dispatch(sendRequestCitiesGet({ token: localStorage.getItem('token') }));
    }
  }, [isAuth]);

  return(
    <Page>
      <ScrolledCol>
        <Container>
          <VerticalSpace>
            {children}
          </VerticalSpace>
        </Container>
      </ScrolledCol>
    </Page>
  );
}

export default KitchenModule;
