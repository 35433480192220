import React from "react";
import { CardSection, Flex, Text, Title } from "../../../../../core/styles";
import styled from "styled-components";
import Card from "../../../../../components/Card";
import { PlusIcon, UserDirIcon } from "../../../../../components/Icons";
import dishes from '../../../../../image/dishes.png'
import { Link } from "react-router-dom";

const ListProductCard = () => {
    return(
        <StyledCard>
            <CardSection>
                <Flex style={{marginBottom: '20px'}} justify='space-between' align='baseline'>
                    <Title level={3}>Всего товаров</Title>
                    <ProductCount>98</ProductCount>
                </Flex>
                <ListProductProgress title={'Без фото'} progress={20} maxProgress={98}/>
                <ListProductProgress title={'Без КБЖУ'} progress={85} maxProgress={98}/>

                <Flex style={{marginTop: '20px'}} justify='space-between' gap='16px'>
                    <Text size='24px' secondary>На модерации</Text>
                    <Text size='24px' secondary><UserDirIcon/> 16</Text>
                </Flex>

                <StyledButton to={'/assortment'}>
                    <img src={dishes} alt="dishes"/>
                    <Flex align='center' gap='5px'>
                        <Text size='16px' color='#858080'>Добавить товары</Text>
                        <PlusIcon color='#858080'/>
                    </Flex>
                </StyledButton>
            </CardSection>
        </StyledCard>
    )
}

export default ListProductCard;

const ListProductProgress = ({ title, progress, maxProgress }) => {
    const danger = (progress / maxProgress) * 100 > 80 ? true : false;
    return (
        <Flex column style={{marginTop: '24px'}}>
            <Flex justify='space-between' align='center' gap='16px'>
                <Title level={4}>{title}</Title>
                <ProgressInfo>
                    {progress}/{maxProgress}
                </ProgressInfo>
            </Flex>
            <ProgressBarWrapper>
                <ProgressBar danger={danger} progress={(progress / maxProgress) * 100} />
            </ProgressBarWrapper>
        </Flex>
    )
}

const StyledCard = styled(Card)`
    flex: 1;
`
const ProductCount = styled.div`
    font-size: 24px;
    font-weight: 600;
`
const StyledButton = styled(Link)`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding: 16px;
    border: dashed 1px var(--color-font);
    border-radius: 24px;
    transition: all .2s;
    cursor: pointer;

    &:hover {
        scale: 1.025;
    }
`
const ProgressInfo = styled.div`
    font-size: 16px;
    color: #888;
`
const ProgressBarWrapper = styled.div`
    position: relative;
    height: 5px;
    background-color: #e5e5e5;
    border-radius: 5px;
    margin-top: 20px;
`;
const ProgressBar = styled.div`
    height: 100%;
    width: ${(props) => props.progress}%;
    background-color: ${(props) => props.danger ? 'var(--color-accent)': 'var(--color-font)'};
    border-radius: 5px;
`