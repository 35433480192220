import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { persistor } from '../redux/configureStore';
import { logout } from '../redux/reducers/utils/authReducer';
import { Dropdown, Avatar, Select } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Flex, Text, Title } from '../core/styles';

const timezones = [
  { label: 'GMT-12:00', value: 'Etc/GMT-12' },
  { label: 'GMT-11:00', value: 'Etc/GMT-11' },
  { label: 'GMT-10:00', value: 'Etc/GMT-10' },
  { label: 'GMT-09:00', value: 'Etc/GMT-9' },
  { label: 'GMT-08:00', value: 'Etc/GMT-8' },
  { label: 'GMT-07:00', value: 'Etc/GMT-7' },
  { label: 'GMT-06:00', value: 'Etc/GMT-6' },
  { label: 'GMT-05:00', value: 'Etc/GMT-5' },
  { label: 'GMT-04:00', value: 'Etc/GMT-4' },
  { label: 'GMT-03:00', value: 'Etc/GMT-3' },
  { label: 'GMT-02:00', value: 'Etc/GMT-2' },
  { label: 'GMT-01:00', value: 'Etc/GMT-1' },
  { label: 'GMT+00:00', value: 'Etc/GMT' },
  { label: 'GMT+01:00', value: 'Etc/GMT+1' },
  { label: 'GMT+02:00', value: 'Etc/GMT+2' },
  { label: 'GMT+03:00', value: 'Etc/GMT+3' },
  { label: 'GMT+04:00', value: 'Etc/GMT+4' },
  { label: 'GMT+05:00', value: 'Etc/GMT+5' },
  { label: 'GMT+06:00', value: 'Etc/GMT+6' },
  { label: 'GMT+07:00', value: 'Etc/GMT+7' },
  { label: 'GMT+08:00', value: 'Etc/GMT+8' },
  { label: 'GMT+09:00', value: 'Etc/GMT+9' },
  { label: 'GMT+10:00', value: 'Etc/GMT+10' },
  { label: 'GMT+11:00', value: 'Etc/GMT+11' },
  { label: 'GMT+12:00', value: 'Etc/GMT+12' },
];

const HeaderProfileDropdownComponent = () => {
  const { userProfile, logoutStatus } = useSelector((store) => store.auth);
  const dispatch = useDispatch();
  const [selectedTimezone, setSelectedTimezone] = useState(null);

  const handleLogout = () => {
    const requestData = {
      token: localStorage.getItem('token')
    }

    dispatch(logout(requestData));
  };

  const handleTimezoneChange = (value) => {
    setSelectedTimezone(value);
    console.log('Выбранный часовой пояс:', value);
  };

  useEffect(() => {
    if (logoutStatus === 'resolved') {
      localStorage.removeItem('token');
      localStorage.removeItem('session');
      persistor.purge();
    }
  },[logoutStatus])

  const items = [
    {
      key: '1',
      label: userProfile
        ? 
        <div onClick={(e) => e.stopPropagation()}>
          <Text secondary size='12px'>{userProfile.userId}</Text>
          <Title level={5}>{userProfile.phone}</Title>
          <Text>{userProfile.name}</Text>
          <Flex align='center' gap='16px'>
            <Text>Часовой пояс</Text>
            <Select
              placeholder="Часовой пояс"
              value={selectedTimezone}
              onChange={handleTimezoneChange}
              options={timezones}
              style={{ width: 150 }}
              // onClick={(e) => e.stopPropagation()}
            />
          </Flex>
        </div>
        : 'Loading...',
    },
    {
      key: '2',
      label: (
        <a onClick={handleLogout}>Выйти</a>
      ),
    },
  ];

  return (
    <StyledDropdown menu={{ items }} trigger={['click']}>
      <Avatar icon={<UserOutlined />} onClick={(e) => e.preventDefault()}/>
    </StyledDropdown>
  );
};

export default HeaderProfileDropdownComponent;

const StyledDropdown = styled(Dropdown)`
  margin-left: auto;
`
