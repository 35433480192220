import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { MoneyCheckIcon, MoneyTimeIcon, MoneyChangeIcon } from '../../../../../components/Icons';
import { Flex, Text, Title } from '../../../../../core/styles';
import Card from '../../../../../components/Card';


const MainStats = () => {
  return (
    <Wrapper>
        <StatCard>
            <Flex align='center' gap='16px'>
                <IconWrapper bg='#D9F4EC'>
                    <MoneyCheckIcon color='#49BC9A'/>
                </IconWrapper>
                <Flex column gap='8px'>
                    <Text style={{textWrap: 'nowrap'}} secondary>Ваша прибыль</Text>
                    <Title>0</Title>
                </Flex>
            </Flex>
        </StatCard>
        <StatCard>
            <Flex align='center' gap='16px'>
                <IconWrapper bg='#FFEAD2'>
                    <MoneyTimeIcon color='#FF8800'/>
                </IconWrapper>
                <Flex column gap='8px'>
                    <Text style={{textWrap: 'nowrap'}} secondary>Акты на подпись</Text>
                    <Title>0</Title>
                </Flex>
            </Flex>
        </StatCard>
        <StatCard>
            <Flex align='center' gap='16px'>
                <IconWrapper bg='#FFEAEA'>
                    <MoneyChangeIcon color='#FF4444'/>
                </IconWrapper>
                <Flex column gap='8px'>
                    <Text style={{textWrap: 'nowrap'}} secondary>Претензий</Text>
                    <Title>0</Title>
                </Flex>
            </Flex>
        </StatCard>
    </Wrapper>
  );
}

export default MainStats;

const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`

const StatCard = styled(Card)`
  flex: 1 1 250px;
`

const IconWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: ${(props) => props.bg && props.bg};
  border-radius: 48px;
`