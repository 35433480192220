import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";
import axios from 'axios';
import apiInstance, { apiRequest } from '../../../../api';
import { selectCommonState } from '../commonReducer';

const initialState = {
  //Loadings
  formFiltersLoading: false,
  locationsLibLoading: false,
  mapDataLoading: false,
  geocodeAddressLoading: false,
  createLocationLoading: false,
  updateLocationLoading: false,
  deleteLocationLoading: false,
  addLocationAddressesLoading: false,
  removeLocationAddressesLoading: false,
  createLocationMidpointLoading: false,
  updateLocationMidpointLoading: false,
  deleteLocationMidpointLoading: false,
  addAddressToLocationMidpointLoading: false,
  updateAddressToLocationMidpointLoading: false,
  removeAddressToLocationMidpointLoading: false,
  createLocationEntranceLoading: false,
  updateLocationEntranceLoading: false,
  deleteLocationEntranceLoading: false,
  addAddressToLocationEntranceLoading: false,
  updateAddressToLocationEntranceLoading: false,
  removeAddressToLocationEntranceLoading: false,
  currentLocationAddressesLoading: false,

  //Form Filters
  formFiltersShow: false,
  formFilters: [],
  selectedFilters: {
    city: null,
    cityCoordinates: [53.5303, 49.3461],
    delivery: null,
    timePeriod: null,
    date: null,
    polygons: null,
  },

  mapMode: 'defaultMode',
  searchQuery: '',
  isNeedUpdateData: false,

  //Locations
  emptyLocationsShow: false,
  locationsShow: false,
  locationsLib: [],
  allEmptylocationsShow: false,
  locations: [],
  currentLocation: null,
  currentLocationAddresses: [],
  geocodeAddress: null,
  locationAddressAddedArray: [],

  //Points
  points: [],

  //Couriers
  couriersShow: false,
  detailCouriersShow: false,
  couriers: [],
  currentCourierId: null,
  
  mapAddressLocation: null,

  selectedOrder: null,
  selectedOrderArray: [],
  selectedCourierToAppoint: null,
  firstPlacemarkToGroupOrders: null,
  secondPlacemarkToGroupOrders: null,
  isPlacemarkDraggable: false,
  selectedOrderOldCoords: null,
  selectedOrderNewCoords: null,

  //Address Change Geopoint
  addressChangeGeopointStatus: null,

  //Assign Courier To Addresses
  assignCourierToAddressesStatus: null,

  //Update Address Comment
  updateAddressCommentStatus: null,

  //Update Order Comment
  updateOrderCommentStatus: null,

  //Couriers Geo Location
  whereCouriersGetStatus: null,
  couriersLocation: [],
  couriersRoute: [],
  selectedCourierLocationAndRoute: {
    showAllCourier: false,
    locationCourierIds: [],
    trackCourierIds: [],
  },

  //Locations
  updateOrderCommentStatus: null,

  //Couriers Detail
  couriersDetailGetStatus: null,
  couriersDetail: [],
  courierRouteSaveStatus: null,
};

export const fetchFormFilters = createAsyncThunk(
  'deliveryMap/fetchFormFilters',
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiRequest('/map/filterInfoForMap?XDEBUG_SESSION_START=MyVSCode', { data });

      if (response.result) {
        return response.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const fetchLocationsLib = createAsyncThunk(
  'deliveryMap/fetchLocationsLib',
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiRequest('/delivery/locations/getByCityId?XDEBUG_SESSION_START=MyVSCode', { data });

      if (response.result) {
        return response.data.locations;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const fetchDataForMap = createAsyncThunk(
  'deliveryMap/fetchDataForMap',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await apiRequest('/map/dataForMap?XDEBUG_SESSION_START=MyVSCode', { data });

      if (response.result) {
        const state = getState();
        const locationsLib = state.deliveryMap.locationsLib;
        const locationsAllMap = new Map(locationsLib.map(item => [item.id, item]));

        const modifyLocations = response.data.locations.map(location => {
            location.courierId = Object.values(location.addresses)[0].courierId;
            location.courierName = Object.values(location.addresses)[0].courierName;
            location.courierColor = Object.values(location.addresses)[0].courierColor;
          const matchingItem = locationsAllMap.get(location.locationId);
          return { ...location, ...matchingItem };
        });

        return {
          locations: modifyLocations,
          noLocations: Object.values(response.data.noLocations),
          couriers: response.data.couriers
        };
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const fetchGeocode = createAsyncThunk(
  'deliveryMap/fetchGeocode',
  async (data, { getState, rejectWithValue, dispatch }) => {
  const reversCoords = [data[1], data[0]]
  try {
    const response = await axios.get('https://geocode-maps.yandex.ru/1.x/', {
      params: {
        apikey: '32a35624-b004-474d-b343-426da5feddae',
        geocode: reversCoords.join(','),
        format: 'json',
        kind: 'house',
        results: 1,
      },
    });

    const geoObjectCollection = response.data.response.GeoObjectCollection;

    if (geoObjectCollection.featureMember.length > 0) {
      const citiesLib = getState().settingsCatalogs.cities;
      const geoObject = geoObjectCollection.featureMember[0].GeoObject;
      const coords = geoObject?.Point?.pos?.split(' ').map(Number).reverse();
      const address = geoObject.metaDataProperty.GeocoderMetaData.Address;
      const addressFormatted = address.formatted;
      const addressDetails = geoObject.metaDataProperty.GeocoderMetaData.AddressDetails;
      const components = address?.Components || [];

      const getComponent = (kind) => {
        const filteredComponents = components.filter(component => component.kind === kind);
        return filteredComponents.length > 0 ? filteredComponents[filteredComponents.length - 1].name : null;
      };

      const findKeyInObject = (obj, key) => {
        if (obj === null || typeof obj !== 'object') {
          return undefined;
        }
        if (obj.hasOwnProperty(key)) {
          return obj[key];
        }
        for (const k in obj) {
          if (obj.hasOwnProperty(k)) {
            const result = findKeyInObject(obj[k], key);
            if (result !== undefined) {
              return result;
            }
          }
        }
        return undefined;
      };

      const country = getComponent('country') || '';
      const administrativeArea = getComponent('province') || '';
      const city = getComponent('locality') || getComponent('area') || '';
      const street = getComponent('street') || '';
      const house = getComponent('house') || '';
      const postalCodeNumber = findKeyInObject(addressDetails, 'PostalCodeNumber') || '';

      return {
        formatted: addressFormatted,
        coords: coords,
        country: country,
        administrativeArea: administrativeArea,
        cityId: citiesLib.find(item => item.name.toLowerCase() === city.toLowerCase())?.id,
        city: city,
        street: street,
        house: house,
        postalCodeNumber: postalCodeNumber,
      };
    } else {
      return rejectWithValue('Failed to fetch data');
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
  }
});

export const fetchCreateLocation = createAsyncThunk(
  'deliveryMap/fetchCreateLocation',
  async (data, { getState, rejectWithValue, dispatch }) => {
    const api = '/delivery/locations/create?XDEBUG_SESSION_START=MyVSCode';
    const state = getState();
    const commonState = selectCommonState(state);
    const fullApi = commonState.apiUrl + api;

    try {
      const response = await apiInstance.post(fullApi, JSON.stringify({ data }));

      if (response.data.result) {
        return response.data.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const fetchUpdateLocation = createAsyncThunk(
  'deliveryMap/fetchUpdateLocation',
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiRequest('/delivery/locations/update?XDEBUG_SESSION_START=MyVSCode', { data });

      if (response.result) {
        return response.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const fetchDeleteLocation = createAsyncThunk(
  'deliveryMap/fetchDeleteLocation',
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiRequest('/delivery/locations/delete?XDEBUG_SESSION_START=MyVSCode', { data });

      if (response.result) {
        return response.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const fetchAddLocationAddresses = createAsyncThunk(
  'deliveryMap/fetchAddLocationAddresses',
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiRequest('/delivery/locations/addresses/create?XDEBUG_SESSION_START=MyVSCode', { data });

      if (response.result) {
        return response.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const fetchRemoveLocationAddresses = createAsyncThunk(
  'deliveryMap/fetchRemoveLocationAddresses',
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiRequest('/delivery/locations/addresses/delete?XDEBUG_SESSION_START=MyVSCode', { data });

      if (response.result) {
        return response.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const fetchCreateLocationMidpoint = createAsyncThunk(
  'deliveryMap/fetchCreateLocationMidpoint',
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiRequest('/delivery/locations/meetingPoints/create?XDEBUG_SESSION_START=MyVSCode', { data });

      if (response.result) {
        return response.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const fetchUpdateLocationMidpoint = createAsyncThunk(
  'deliveryMap/fetchUpdateLocationMidpoint',
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiRequest('/delivery/locations/meetingPoints/update?XDEBUG_SESSION_START=MyVSCode', { data });

      if (response.result) {
        return response.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const fetchDeleteLocationMidpoint = createAsyncThunk(
  'deliveryMap/fetchDeleteLocationMidpoint',
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiRequest('/delivery/locations/meetingPoints/delete?XDEBUG_SESSION_START=MyVSCode', { data });

      if (response.result) {
        return response.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const fetchAddAddressToLocationMidpoint = createAsyncThunk(
  'deliveryMap/fetchAddAddressToLocationMidpoint',
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiRequest('/delivery/locations/meetingPoints/addresses/create?XDEBUG_SESSION_START=MyVSCode', { data });

      if (response.result) {
        return response.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const fetchUpdateAddressToLocationMidpoint = createAsyncThunk(
  'deliveryMap/fetchUpdateAddressToLocationMidpoint',
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiRequest('/delivery/locations/meetingPoints/addresses/update?XDEBUG_SESSION_START=MyVSCode', { data });

      if (response.result) {
        return response.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const fetchRemoveAddressToLocationMidpoint = createAsyncThunk(
  'deliveryMap/fetchRemoveAddressToLocationMidpoint',
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiRequest('/delivery/locations/meetingPoints/addresses/delete?XDEBUG_SESSION_START=MyVSCode', { data });

      if (response.result) {
        return response.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const fetchCreateLocationEntrance = createAsyncThunk(
  'deliveryMap/fetchCreateLocationEntrance',
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiRequest('/delivery/locations/entrance/create?XDEBUG_SESSION_START=MyVSCode', { data });

      if (response.result) {
        return response.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const fetchUpdateLocationEntrance = createAsyncThunk(
  'deliveryMap/fetchUpdateLocationEntrance',
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiRequest('/delivery/locations/entrance/update?XDEBUG_SESSION_START=MyVSCode', { data });

      if (response.result) {
        return response.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const fetchDeleteLocationEntrance = createAsyncThunk(
  'deliveryMap/fetchDeleteLocationEntrance',
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiRequest('/delivery/locations/entrance/delete?XDEBUG_SESSION_START=MyVSCode', { data });

      if (response.result) {
        return response.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const fetchAddAddressToLocationEntrance = createAsyncThunk(
  'deliveryMap/fetchAddAddressToLocationEntrance',
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiRequest('/delivery/locations/entrance/addresses/create?XDEBUG_SESSION_START=MyVSCode', { data });

      if (response.result) {
        return response.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const fetchUpdateAddressToLocationEntrance = createAsyncThunk(
  'deliveryMap/fetchAddUpdateressToLocationEntrance',
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiRequest('/delivery/locations/entrance/addresses/update?XDEBUG_SESSION_START=MyVSCode', { data });

      if (response.result) {
        return response.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const fetchRemoveAddressToLocationEntrance = createAsyncThunk(
  'deliveryMap/fetchRemoveAddressToLocationEntrance',
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiRequest('/delivery/locations/entrance/addresses/delete?XDEBUG_SESSION_START=MyVSCode', { data });

      if (response.result) {
        return response.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const fetchCurrentLocationAddresses = createAsyncThunk(
  'deliveryMap/fetchCurrentLocationAddresses',
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiRequest('/delivery/locations/getAddressesByLocationId?XDEBUG_SESSION_START=MyVSCode', { data });

      if (response.result) {
        return response.data.location[0].addresses.map(address => {return {...address.addresse[0], locationAddressId: address.id}});
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

const deliveryMapSlice = createSlice({
  name: 'deliveryMap',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchFormFilters.pending, (state) => {
        state.formFiltersLoading = true;
      })
      .addCase(fetchFormFilters.fulfilled, (state, action) => {
        state.formFiltersLoading = false;
        state.formFilters = action.payload.cities;
      })
      .addCase(fetchFormFilters.rejected, (state, action) => {
        state.formFiltersLoading = false;
      })
      .addCase(fetchLocationsLib.pending, (state) => {
        state.locationsLibLoading = true;
      })
      .addCase(fetchLocationsLib.fulfilled, (state, action) => {
        state.locationsLibLoading = false;
        state.locationsLib = action.payload;
        state.isNeedUpdateData = false;
      })
      .addCase(fetchLocationsLib.rejected, (state, action) => {
        state.locationsLibLoading = false;
        state.isNeedUpdateData = false;
      })
      .addCase(fetchDataForMap.pending, (state) => {
        state.mapDataLoading = true;
      })
      .addCase(fetchDataForMap.fulfilled, (state, action) => {
        state.mapDataLoading = false;
        state.locations = action.payload.locations;
        state.points = action.payload.noLocations;
        state.couriers = action.payload.couriers;
        state.isNeedUpdateData = false;
      })
      .addCase(fetchDataForMap.rejected, (state, action) => {
        state.mapDataLoading = false;
        state.isNeedUpdateData = false;
      })
      .addCase(fetchGeocode.pending, (state) => {
        state.geocodeAddressLoading = true;
      })
      .addCase(fetchGeocode.fulfilled, (state, action) => {
        state.geocodeAddressLoading = false;
        state.geocodeAddress = action.payload;
      })
      .addCase(fetchGeocode.rejected, (state, action) => {
        state.geocodeAddressLoading = false;
      })
      .addCase(fetchCreateLocation.pending, (state) => {
        state.createLocationLoading = true;
      })
      .addCase(fetchCreateLocation.fulfilled, (state, action) => {
        state.createLocationLoading = false;
        state.isNeedUpdateData = true;
      })
      .addCase(fetchCreateLocation.rejected, (state, action) => {
        state.createLocationLoading = false;
      })
      .addCase(fetchUpdateLocation.pending, (state) => {
        state.updateLocationLoading = true;
      })
      .addCase(fetchUpdateLocation.fulfilled, (state, action) => {
        state.updateLocationLoading = false;
        state.isNeedUpdateData = true;
      })
      .addCase(fetchUpdateLocation.rejected, (state, action) => {
        state.updateLocationLoading = false;
      })
      .addCase(fetchDeleteLocation.pending, (state) => {
        state.deleteLocationLoading = true;
      })
      .addCase(fetchDeleteLocation.fulfilled, (state, action) => {
        state.deleteLocationLoading = false;
        state.isNeedUpdateData = true;
      })
      .addCase(fetchDeleteLocation.rejected, (state, action) => {
        state.deleteLocationLoading = false;
      })
      .addCase(fetchAddLocationAddresses.pending, (state) => {
        state.addLocationAddressesLoading = true;
      })
      .addCase(fetchAddLocationAddresses.fulfilled, (state, action) => {
        state.addLocationAddressesLoading = false;
        state.isNeedUpdateData = true;
      })
      .addCase(fetchAddLocationAddresses.rejected, (state, action) => {
        state.addLocationAddressesLoading = false;
      })
      .addCase(fetchRemoveLocationAddresses.pending, (state) => {
        state.removeLocationAddressesLoading = true;
      })
      .addCase(fetchRemoveLocationAddresses.fulfilled, (state, action) => {
        state.removeLocationAddressesLoading = false;
        state.isNeedUpdateData = true;
      })
      .addCase(fetchRemoveLocationAddresses.rejected, (state, action) => {
        state.removeLocationAddressesLoading = false;
      })
      .addCase(fetchCreateLocationMidpoint.pending, (state) => {
        state.createLocationMidpointLoading = true;
      })
      .addCase(fetchCreateLocationMidpoint.fulfilled, (state, action) => {
        state.createLocationMidpointLoading = false;
        state.isNeedUpdateData = true;
      })
      .addCase(fetchCreateLocationMidpoint.rejected, (state, action) => {
        state.createLocationMidpointLoading = false;
      })
      .addCase(fetchUpdateLocationMidpoint.pending, (state) => {
        state.updateLocationMidpointLoading = true;
      })
      .addCase(fetchUpdateLocationMidpoint.fulfilled, (state, action) => {
        state.updateLocationMidpointLoading = false;
        state.isNeedUpdateData = true;
      })
      .addCase(fetchUpdateLocationMidpoint.rejected, (state, action) => {
        state.updateLocationMidpointLoading = false;
      })
      .addCase(fetchDeleteLocationMidpoint.pending, (state) => {
        state.deleteLocationMidpointLoading = true;
      })
      .addCase(fetchDeleteLocationMidpoint.fulfilled, (state, action) => {
        state.deleteLocationMidpointLoading = false;
        state.isNeedUpdateData = true;
      })
      .addCase(fetchDeleteLocationMidpoint.rejected, (state, action) => {
        state.deleteLocationMidpointLoading = false;
      })
      .addCase(fetchAddAddressToLocationMidpoint.pending, (state) => {
        state.addAddressToLocationMidpointLoading = true;
      })
      .addCase(fetchAddAddressToLocationMidpoint.fulfilled, (state, action) => {
        state.addAddressToLocationMidpointLoading = false;
        state.isNeedUpdateData = true;
      })
      .addCase(fetchAddAddressToLocationMidpoint.rejected, (state, action) => {
        state.addAddressToLocationMidpointLoading = false;
      })
      .addCase(fetchUpdateAddressToLocationMidpoint.pending, (state) => {
        state.updateAddressToLocationEntranceLoading = true;
      })
      .addCase(fetchUpdateAddressToLocationMidpoint.fulfilled, (state, action) => {
        state.updateAddressToLocationEntranceLoading = false;
        state.isNeedUpdateData = true;
      })
      .addCase(fetchUpdateAddressToLocationMidpoint.rejected, (state, action) => {
        state.updateAddressToLocationEntranceLoading = false;
      })
      .addCase(fetchRemoveAddressToLocationMidpoint.pending, (state) => {
        state.removeAddressToLocationMidpointLoading = true;
      })
      .addCase(fetchRemoveAddressToLocationMidpoint.fulfilled, (state, action) => {
        state.removeAddressToLocationMidpointLoading = false;
        state.isNeedUpdateData = true;
      })
      .addCase(fetchRemoveAddressToLocationMidpoint.rejected, (state, action) => {
        state.removeAddressToLocationMidpointLoading = false;
      })
      .addCase(fetchCreateLocationEntrance.pending, (state) => {
        state.createLocationEntranceLoading = true;
      })
      .addCase(fetchCreateLocationEntrance.fulfilled, (state, action) => {
        state.createLocationEntranceLoading = false;
        state.isNeedUpdateData = true;
      })
      .addCase(fetchCreateLocationEntrance.rejected, (state, action) => {
        state.createLocationEntranceLoading = false;
      })
      .addCase(fetchUpdateLocationEntrance.pending, (state) => {
        state.updateLocationEntranceLoading = true;
      })
      .addCase(fetchUpdateLocationEntrance.fulfilled, (state, action) => {
        state.updateLocationEntranceLoading = false;
        state.isNeedUpdateData = true;
      })
      .addCase(fetchUpdateLocationEntrance.rejected, (state, action) => {
        state.updateLocationEntranceLoading = false;
      })
      .addCase(fetchDeleteLocationEntrance.pending, (state) => {
        state.deleteLocationEntranceLoading = true;
      })
      .addCase(fetchDeleteLocationEntrance.fulfilled, (state, action) => {
        state.deleteLocationEntranceLoading = false;
        state.isNeedUpdateData = true;
      })
      .addCase(fetchDeleteLocationEntrance.rejected, (state, action) => {
        state.deleteLocationEntranceLoading = false;
      })
      .addCase(fetchAddAddressToLocationEntrance.pending, (state) => {
        state.addAddressToLocationEntranceLoading = true;
      })
      .addCase(fetchAddAddressToLocationEntrance.fulfilled, (state, action) => {
        state.addAddressToLocationEntranceLoading = false;
        state.isNeedUpdateData = true;
      })
      .addCase(fetchAddAddressToLocationEntrance.rejected, (state, action) => {
        state.addAddressToLocationEntranceLoading = false;
      })
      .addCase(fetchUpdateAddressToLocationEntrance.pending, (state) => {
        state.updateAddressToLocationEntranceLoading = true;
      })
      .addCase(fetchUpdateAddressToLocationEntrance.fulfilled, (state, action) => {
        state.updateAddressToLocationEntranceLoading = false;
        state.isNeedUpdateData = true;
      })
      .addCase(fetchUpdateAddressToLocationEntrance.rejected, (state, action) => {
        state.updateAddressToLocationEntranceLoading = false;
      })
      .addCase(fetchRemoveAddressToLocationEntrance.pending, (state) => {
        state.removeAddressToLocationEntranceLoading = true;
      })
      .addCase(fetchRemoveAddressToLocationEntrance.fulfilled, (state, action) => {
        state.removeAddressToLocationEntranceLoading = false;
        state.isNeedUpdateData = true;
      })
      .addCase(fetchRemoveAddressToLocationEntrance.rejected, (state, action) => {
        state.removeAddressToLocationEntranceLoading = false;
      })
      .addCase(fetchCurrentLocationAddresses.pending, (state) => {
        state.currentLocationAddressesLoading = true;
      })
      .addCase(fetchCurrentLocationAddresses.fulfilled, (state, action) => {
        state.currentLocationAddressesLoading = false;
        state.currentLocationAddresses = action.payload;
      })
      .addCase(fetchCurrentLocationAddresses.rejected, (state, action) => {
        state.currentLocationAddressesLoading = false;
      })
      .addCase(PURGE, () => initialState);
  },
  reducers: {
    //Form Filters
    setFormFiltersShow: (state, action) => {
      state.formFiltersShow = action.payload;
    },
    updateFormFilterData: (state, action) => {
      const { ...rest } = action.payload;

      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          ...rest,
        },
      };
    },

    setMapMode: (state, action) => {
      state.mapMode = action.payload;
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload.toLowerCase();
    },

    //Locations
    setLocationsShow: (state, action) => {
      state.locationsShow = action.payload;
    },
    setCurrentLocation: (state, action) => {
      state.currentLocation = action.payload;
      state.selectedOrderOldCoords = null;
      state.selectedOrderNewCoords = null;
      state.selectedOrderArray = [];
    },
    setAllEmptylocationsShow: (state, action) => {
      state.allEmptylocationsShow = action.payload;
    },
    setLocationAddressAddedArray: (state, action) => {
      state.locationAddressAddedArray = action.payload;
    },

    //Points
    selectOrder: (state, action) => {
      state.selectedOrder = action.payload;
      state.selectedOrderOldCoords = action.payload ? action.payload.coordinates : null;
      state.selectedOrderNewCoords = action.payload ? action.payload.coordinates : null;
    },
    selectOrderArray: (state, action) => {
      state.selectedOrderArray = action.payload;
      state.currentLocation = null;
      state.selectedOrder = null;
    },
    updateMapDataPoints: (state, action) => {
      const updatedPoints = action.payload;

      return {
        ...state,
        points: state.points.map((point) => {
          const matchingPoint = updatedPoints.find((updatedPoint) => updatedPoint.id === point.addressId);

          return matchingPoint ? { ...point, ...matchingPoint } : point;
        }),
      };
    },

    //Geocode
    setMapAddressLocation: (state, action) => {
      state.mapAddressLocation = action.payload;
    },
    clearGeocodeAddress: (state) => {
      state.geocodeAddress = null;
      state.mapMode = 'defaultMode';
    },

    //Courier
    setCouriersShow: (state, action) => {
      state.couriersShow = action.payload;
    },
    setDetailCouriersShow: (state, action) => {
      state.detailCouriersShow = action.payload;
    },
    setCurrentCourierId: (state, action) => {
      state.currentCourierId = action.payload;
    },
    selectCourierToAppoint: (state, action) => {
      state.selectedCourierToAppoint = action.payload;
    },
    setFirstPlacemarkToGroupOrders: (state, action) => {
      state.firstPlacemarkToGroupOrders = action.payload;
    },
    setSecondPlacemarkToGroupOrders: (state, action) => {
      state.secondPlacemarkToGroupOrders = action.payload;
    },

    setPlacemarkDragActivate: (state) => {
      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          draggable: true,
        },
        isPlacemarkDraggable: true,
      };
    },

    setPlacemarkDragCancel: (state) => {
      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          coordinates: state.selectedOrderOldCoords,
          draggable: false,
        },
        isPlacemarkDraggable: false,
      };
    },

    setSelectedOrderOldCoords: (state, action) => {
      state.selectedOrderOldCoords = action.payload;
    },
    setSelectedOrderNewCoords: (state, action) => {
      state.selectedOrderNewCoords = action.payload;
    },

    //Address Change Geopoint
    sendRequestForAddressChangeGeopoint: (state) => { state.addressChangeGeopointStatus = 'pending'; },
    sendRequestForAddressChangeGeopointSuccess: (state) => { state.addressChangeGeopointStatus = 'resolved'; },
    sendRequestForAddressChangeGeopointError: (state) => { state.addressChangeGeopointStatus = 'rejected'; },
    sendRequestForAddressChangeGeopointClear: (state) => { state.addressChangeGeopointStatus = null; },

    //Assign Courier To Addresses
    sendRequestForAssignCourierToAddresses: (state) => { state.assignCourierToAddressesStatus = 'pending'; },
    sendRequestForAssignCourierToAddressesSuccess: (state) => { state.assignCourierToAddressesStatus = 'resolved'; },
    sendRequestForAssignCourierToAddressesError: (state) => { state.assignCourierToAddressesStatus = 'rejected'; },
    sendRequestForAssignCourierToAddressesClear: (state) => { state.assignCourierToAddressesStatus = null; },

    //Update Address Comment
    sendRequestForUpdateAddressComment: (state) => { state.updateAddressCommentStatus = 'pending'; },
    sendRequestForUpdateAddressCommentSuccess: (state) => { state.updateAddressCommentStatus = 'resolved'; },
    sendRequestForUpdateAddressCommentError: (state) => { state.updateAddressCommentStatus = 'rejected'; },
    sendRequestForUpdateAddressCommentClear: (state) => { state.updateAddressCommentStatus = null; },

    //Update Order Comment
    sendRequestForUpdateOrderComment: (state) => { state.updateOrderCommentStatus = 'pending'; },
    sendRequestForUpdateOrderCommentSuccess: (state) => { state.updateOrderCommentStatus = 'resolved'; },
    sendRequestForUpdateOrderCommentError: (state) => { state.updateOrderCommentStatus = 'rejected'; },
    sendRequestForUpdateOrderCommentClear: (state) => { state.updateOrderCommentStatus = null; },

    //Couriers Geo Location
    sendRequestWhereCouriersGet: (state) => { state.whereCouriersGetStatus = 'pending'; },
    sendRequestWhereCouriersGetSuccess: (state) => { state.whereCouriersGetStatus = 'resolved'; },
    sendRequestWhereCouriersGetError: (state) => { state.whereCouriersGetStatus = 'rejected'; },
    clearWhereCouriersGetStatus: (state) => { state.whereCouriersGetStatus = null; },
    setCouriersLocation: (state, action) => {
      const data = action.payload;

      const getCustomPlacemark = (rotate = 0, color = '#000000') => {
        let svgString = `
          <svg xmlns="http://www.w3.org/2000/svg" transform="rotate(${rotate})" width="1920px" height="960px" viewBox="0 0 1920 960" fill="none">
            <g><path style="opacity:0.977" fill="#000000" d="M 1101.5,-0.5 C 1108.83,-0.5 1116.17,-0.5 1123.5,-0.5C 1139.95,3.15351 1152.12,12.4868 1160,27.5C 1165.94,43.1635 1171.78,58.8301 1177.5,74.5C 1243.83,74.6667 1310.17,74.5 1376.5,74C 1403.2,61.9915 1431.2,55.3248 1460.5,54C 1493.5,53.3333 1526.5,53.3333 1559.5,54C 1628.98,56.5315 1693.65,75.1981 1753.5,110C 1815.19,149.709 1859.36,203.875 1886,272.5C 1905.45,324.912 1916.61,378.912 1919.5,434.5C 1919.5,464.5 1919.5,494.5 1919.5,524.5C 1916.61,580.088 1905.45,634.088 1886,686.5C 1840.28,798.146 1758.45,867.313 1640.5,894C 1613.78,899.721 1586.78,903.388 1559.5,905C 1526.5,905.667 1493.5,905.667 1460.5,905C 1431.2,903.675 1403.2,897.009 1376.5,885C 1310.17,884.5 1243.83,884.333 1177.5,884.5C 1171.31,904.213 1163.47,923.213 1154,941.5C 1145.49,950.498 1135.32,956.498 1123.5,959.5C 1116.17,959.5 1108.83,959.5 1101.5,959.5C 1090.29,956.073 1079.29,951.74 1068.5,946.5C 1068.17,945.667 1067.83,944.833 1067.5,944C 1072.41,923.872 1077.07,903.705 1081.5,883.5C 992.833,883.333 904.166,883.5 815.5,884C 719.438,888.171 623.438,893.504 527.5,900C 436.458,907.384 346.125,902.384 256.5,885C 189.923,872.272 124.256,855.939 59.5,836C 46.4117,827.673 37.2451,816.173 32,801.5C 19.1651,762.164 11.1651,721.831 8,680.5C 3.94164,637.088 1.10831,593.755 -0.5,550.5C -0.5,503.167 -0.5,455.833 -0.5,408.5C 1.19948,349.396 6.03281,290.396 14,231.5C 17.8084,201.258 25.8084,172.258 38,144.5C 43.5089,135.624 50.6756,128.457 59.5,123C 146.858,95.7283 235.858,75.7283 326.5,63C 376.659,56.8961 426.992,54.8961 477.5,57C 559.219,60.7116 640.886,65.3783 722.5,71C 753.484,72.7094 784.484,74.0428 815.5,75C 904.166,75.5 992.833,75.6667 1081.5,75.5C 1077.07,55.2949 1072.41,35.1282 1067.5,15C 1067.91,12.7859 1069.24,11.4526 1071.5,11C 1081.36,6.44051 1091.36,2.60717 1101.5,-0.5 Z"/></g>
            <g><path style="opacity:1" fill="${color}" d="M 1106.5,21.5 C 1118.39,20.5187 1128.56,24.1854 1137,32.5C 1145.79,53.714 1154.29,75.0473 1162.5,96.5C 1235.5,97.6666 1308.5,97.8333 1381.5,97C 1403.75,85.5186 1427.42,78.8519 1452.5,77C 1495.18,74.9261 1537.85,75.2594 1580.5,78C 1633.1,82.7203 1682.77,97.387 1729.5,122C 1742.61,129.634 1755.11,138.134 1767,147.5C 1766.05,183.025 1775.05,215.692 1794,245.5C 1811.57,269.704 1835.4,282.537 1865.5,284C 1877,312.508 1885.17,342.008 1890,372.5C 1899.27,439.06 1899.94,505.727 1892,572.5C 1887.45,607.817 1878.61,641.983 1865.5,675C 1835.4,676.461 1811.57,689.295 1794,713.5C 1775.03,743.279 1766.03,775.946 1767,811.5C 1729.33,840.521 1687.16,860.354 1640.5,871C 1578.4,883.407 1515.73,887.073 1452.5,882C 1427.42,880.148 1403.75,873.481 1381.5,862C 1308.5,861.167 1235.5,861.333 1162.5,862.5C 1154.28,883.946 1145.78,905.279 1137,926.5C 1124.46,938.599 1110.29,940.766 1094.5,933C 1093.98,932.439 1093.64,931.772 1093.5,931C 1099.09,908.133 1104.76,885.299 1110.5,862.5C 1004.5,861.333 898.502,861.167 792.5,862C 708.447,866.082 624.447,871.082 540.5,877C 492.867,879.724 445.2,880.724 397.5,880C 333.034,876.171 269.367,866.838 206.5,852C 161.49,841.581 116.823,829.914 72.5,817C 67.1122,814.945 62.9456,811.445 60,806.5C 53.0817,793.075 47.7484,779.075 44,764.5C 39.1344,741.306 35.1344,717.973 32,694.5C 19.7447,563.951 18.4114,433.285 28,302.5C 30.7957,262.263 37.129,222.596 47,183.5C 50.5112,169.598 56.6779,157.098 65.5,146C 67.6705,144.414 70.0038,143.08 72.5,142C 156.354,116.577 241.687,97.5773 328.5,85C 348.078,82.2851 367.745,80.6185 387.5,80C 430.176,79.2132 472.843,79.5465 515.5,81C 607.802,86.909 700.135,92.2424 792.5,97C 898.502,97.8333 1004.5,97.6667 1110.5,96.5C 1104.67,73.3432 1099,50.1765 1093.5,27C 1097.56,24.2655 1101.89,22.4321 1106.5,21.5 Z"/></g>
            <g><path style="opacity:1" fill="#333317" d="M 666.5,138.5 C 740.839,139.875 815.172,141.542 889.5,143.5C 978.846,142.387 1068.18,140.72 1157.5,138.5C 1158.19,141.897 1158.19,145.23 1157.5,148.5C 1114.12,168.236 1069.12,183.07 1022.5,193C 914.509,215.312 805.342,226.145 695,225.5C 661.153,225.213 627.32,224.379 593.5,223C 562.814,220.715 532.148,218.215 501.5,215.5C 513.619,187.882 533.619,168.049 561.5,156C 577.587,149.515 594.254,145.181 611.5,143C 629.906,140.542 648.239,139.042 666.5,138.5 Z"/></g>
            <g><path style="opacity:1" fill="#1d1d1a" d="M 650.5,143.5 C 731.348,144.421 812.181,146.088 893,148.5C 980.504,146.652 1068,145.152 1155.5,144C 1125.72,157.703 1095.06,169.037 1063.5,178C 968.697,201.924 872.363,215.591 774.5,219C 688.991,222.777 603.658,220.444 518.5,212C 515.441,211.355 512.441,210.522 509.5,209.5C 526.559,179.778 551.892,160.945 585.5,153C 607.14,148.602 628.806,145.435 650.5,143.5 Z"/></g>
            <g><path style="opacity:1" fill="#3b3b21" d="M 702.5,148.5 C 687.381,168.183 679.381,190.516 678.5,215.5C 624.651,215.28 570.984,212.114 517.5,206C 531.529,184.302 550.862,169.302 575.5,161C 599.966,153.743 624.966,149.743 650.5,149C 667.83,148.5 685.163,148.333 702.5,148.5 Z"/></g>
            <g><path style="opacity:1" fill="#1e1e11" d="M 702.5,148.5 C 711.482,149.218 720.482,149.552 729.5,149.5C 715.045,169.095 706.711,191.095 704.5,215.5C 695.833,215.5 687.167,215.5 678.5,215.5C 679.381,190.516 687.381,168.183 702.5,148.5 Z"/></g>
            <g><path style="opacity:1" fill="#3b3b21" d="M 729.5,149.5 C 780.329,151 831.162,152.333 882,153.5C 964.5,151.975 1047,150.809 1129.5,150C 1071.01,172.705 1010.68,188.372 948.5,197C 867.617,209.026 786.283,215.192 704.5,215.5C 706.711,191.095 715.045,169.095 729.5,149.5 Z"/></g>
            <g><path style="opacity:1" fill="#959514" d="M 1679.5,311.5 C 1680.17,311.5 1680.5,311.833 1680.5,312.5C 1735.52,334.169 1790.52,356.169 1845.5,378.5C 1846.53,376.56 1846.53,374.56 1845.5,372.5C 1838.34,348.828 1827.84,326.828 1814,306.5C 1809.53,301.032 1804.7,295.865 1799.5,291C 1768.28,269.221 1734.61,251.887 1698.5,239C 1647.55,221.053 1595.55,208.553 1542.5,201.5C 1539.7,200.424 1536.86,199.424 1534,198.5C 1533.5,201.146 1533.34,203.813 1533.5,206.5C 1532.53,204.052 1532.19,201.385 1532.5,198.5C 1467.2,189.054 1401.53,183.554 1335.5,182C 1329.37,176.614 1329.03,170.948 1334.5,165C 1446.52,164.277 1556.52,178.61 1664.5,208C 1716.38,221.946 1764.38,243.946 1808.5,274C 1821.92,285.08 1832.75,298.247 1841,313.5C 1854.42,338.104 1864.09,364.104 1870,391.5C 1871.22,400.949 1867.05,405.116 1857.5,404C 1787.73,374.948 1717.39,347.281 1646.5,321C 1581.59,300.859 1516.59,281.193 1451.5,262C 1447.67,258.692 1446.51,254.526 1448,249.5C 1450.78,245.359 1454.62,243.859 1459.5,245C 1533.48,266.171 1606.82,288.338 1679.5,311.5 Z"/></g>
            <g><path style="opacity:1" fill="#313017" d="M 1263.5,171.5 C 1299.03,169.73 1330.03,180.563 1356.5,204C 1378.21,224.915 1395.38,249.082 1408,276.5C 1429.76,328.639 1442.1,382.972 1445,439.5C 1448.01,497.101 1443.67,554.101 1432,610.5C 1422.48,651.86 1406.15,690.193 1383,725.5C 1366.02,748.811 1344.52,766.644 1318.5,779C 1294.68,788.134 1270.34,789.801 1245.5,784C 1209.2,776.814 1172.87,769.814 1136.5,763C 1111.68,757.009 1090.18,745.175 1072,727.5C 1065.53,718.889 1061.53,709.222 1060,698.5C 1059.33,552.167 1059.33,405.833 1060,259.5C 1062.63,244.416 1069.79,231.916 1081.5,222C 1095.67,211.915 1111,203.915 1127.5,198C 1173.02,189.461 1218.36,180.628 1263.5,171.5 Z"/></g>
            <g><path style="opacity:1" fill="#202020" d="M 1784.5,172.5 C 1812.06,198.923 1833.72,229.59 1849.5,264.5C 1849.17,264.833 1848.83,265.167 1848.5,265.5C 1830.53,260.355 1816.36,250.022 1806,234.5C 1793.77,215.636 1786.6,194.969 1784.5,172.5 Z"/></g>
            <g><path style="opacity:1" fill="#1b1b1a" d="M 373.5,184.5 C 381.932,183.358 389.598,185.192 396.5,190C 398.049,191.214 399.215,192.714 400,194.5C 401.342,205.882 402.675,217.215 404,228.5C 396.905,238.872 387.072,243.039 374.5,241C 372.167,240.667 369.833,240.333 367.5,240C 366.422,239.326 365.755,238.326 365.5,237C 363.173,223.041 361.173,209.041 359.5,195C 359.833,193.833 360.167,192.667 360.5,191.5C 364.767,188.869 369.101,186.536 373.5,184.5 Z"/></g>
            <g><path style="opacity:1" fill="#3b3b21" d="M 1264.5,187.5 C 1301.59,185.797 1332.42,198.797 1357,226.5C 1376.83,249.143 1391.83,274.81 1402,303.5C 1424.61,371.677 1433.61,441.677 1429,513.5C 1426.87,569.301 1415.2,622.967 1394,674.5C 1381.86,702.63 1364.36,726.796 1341.5,747C 1315.68,768.122 1286.35,775.455 1253.5,769C 1213.21,761.076 1172.88,753.409 1132.5,746C 1117.33,740.751 1103.33,733.418 1090.5,724C 1082.4,716.975 1077.57,708.142 1076,697.5C 1075.33,552.167 1075.33,406.833 1076,261.5C 1077.74,248.848 1083.91,239.015 1094.5,232C 1105.66,224.418 1117.66,218.418 1130.5,214C 1175.31,205.204 1219.98,196.371 1264.5,187.5 Z"/></g>
            <g><path style="opacity:1" fill="#1d1d1a" d="M 1542.5,201.5 C 1573.44,210.486 1604.44,219.319 1635.5,228C 1658.15,235.056 1676.65,247.89 1691,266.5C 1701.9,282.006 1702.24,297.673 1692,313.5C 1691.11,314.081 1690.11,314.415 1689,314.5C 1686.18,313.725 1683.34,313.058 1680.5,312.5C 1680.5,311.833 1680.17,311.5 1679.5,311.5C 1678.96,311.44 1678.62,311.107 1678.5,310.5C 1683.11,301.04 1683.61,291.374 1680,281.5C 1673.01,268.176 1663.51,257.009 1651.5,248C 1639.89,240.36 1627.55,234.026 1614.5,229C 1587.34,221.63 1560.34,214.13 1533.5,206.5C 1533.34,203.813 1533.5,201.146 1534,198.5C 1536.86,199.424 1539.7,200.424 1542.5,201.5 Z"/></g>
            <g><path style="opacity:1" fill="#fdfd00" d="M 1542.5,201.5 C 1595.55,208.553 1647.55,221.053 1698.5,239C 1734.61,251.887 1768.28,269.221 1799.5,291C 1804.7,295.865 1809.53,301.032 1814,306.5C 1827.84,326.828 1838.34,348.828 1845.5,372.5C 1846.53,374.56 1846.53,376.56 1845.5,378.5C 1790.52,356.169 1735.52,334.169 1680.5,312.5C 1683.34,313.058 1686.18,313.725 1689,314.5C 1690.11,314.415 1691.11,314.081 1692,313.5C 1702.24,297.673 1701.9,282.006 1691,266.5C 1676.65,247.89 1658.15,235.056 1635.5,228C 1604.44,219.319 1573.44,210.486 1542.5,201.5 Z"/></g>
            <g><path style="opacity:1" fill="#313117" d="M 412.5,239.5 C 444.389,238.353 464.889,253.019 474,283.5C 474.667,412.167 474.667,540.833 474,669.5C 469.228,700.654 451.061,717.321 419.5,719.5C 368.756,712.209 318.089,704.376 267.5,696C 245.605,691.467 227.439,680.634 213,663.5C 190.129,633.077 174.796,599.077 167,561.5C 149.026,484.602 155.693,409.936 187,337.5C 196.269,316.789 209.102,298.623 225.5,283C 237.676,272.58 251.676,265.913 267.5,263C 316.005,255.36 364.339,247.526 412.5,239.5 Z"/></g>
            <g><path style="opacity:1" fill="#3b3b21" d="M 414.5,255.5 C 436.636,254.481 451.136,264.481 458,285.5C 458.667,414.5 458.667,543.5 458,672.5C 452.935,691.569 440.601,701.903 421,703.5C 372.781,696.38 324.614,688.88 276.5,681C 253.752,678.378 235.585,667.878 222,649.5C 205.463,626.086 193.463,600.42 186,572.5C 165.425,499.397 168.758,427.397 196,356.5C 205.518,330.936 220.351,309.103 240.5,291C 249.366,284.265 259.366,280.265 270.5,279C 318.691,271.468 366.691,263.634 414.5,255.5 Z"/></g>
            <g><path style="opacity:1" fill="#959514" d="M 1857.5,554.5 C 1867.04,553.87 1871.21,558.204 1870,567.5C 1862.48,601.194 1849.48,632.527 1831,661.5C 1824.2,670.629 1816.37,678.796 1807.5,686C 1761.52,716.147 1711.86,738.481 1658.5,753C 1553.36,780.712 1446.36,794.712 1337.5,795C 1331.54,792.497 1329.37,787.997 1331,781.5C 1332.96,778.865 1335.46,777.031 1338.5,776C 1434.41,775.202 1529.08,764.202 1622.5,743C 1660.51,733.584 1697.51,721.251 1733.5,706C 1756.77,695.369 1778.77,682.702 1799.5,668C 1812.72,655.11 1823.22,640.276 1831,623.5C 1837.15,610.043 1842.32,596.209 1846.5,582C 1846.17,581.5 1845.83,581 1845.5,580.5C 1788.06,603.887 1730.39,626.721 1672.5,649C 1601.06,672.693 1529.06,694.527 1456.5,714.5C 1447.66,712.798 1445.16,707.798 1449,699.5C 1451.87,696.537 1455.37,694.704 1459.5,694C 1524.1,675.69 1588.43,656.356 1652.5,636C 1721.53,610.275 1789.86,583.108 1857.5,554.5 Z"/></g>
            <g><path style="opacity:1" fill="#202020" d="M 1847.5,693.5 C 1848.38,693.369 1849.04,693.702 1849.5,694.5C 1833.67,729.157 1812.17,759.824 1785,786.5C 1786.75,760.334 1795.75,737.001 1812,716.5C 1821.65,705.322 1833.49,697.656 1847.5,693.5 Z"/></g>
            <g><path style="opacity:1" fill="#323217" d="M 656.5,733.5 C 762.915,731.012 868.581,738.512 973.5,756C 1030.5,765.512 1085.83,780.845 1139.5,802C 1145.62,804.56 1151.62,807.394 1157.5,810.5C 1158.19,813.77 1158.19,817.103 1157.5,820.5C 1068.18,818.339 978.845,816.672 889.5,815.5C 822.001,817.174 754.501,818.841 687,820.5C 651.358,821.503 616.192,818.003 581.5,810C 551.597,802.07 527.764,785.57 510,760.5C 506.607,755.048 503.774,749.381 501.5,743.5C 553.222,738.666 604.889,735.333 656.5,733.5 Z"/></g>
            <g><path style="opacity:1" fill="#1d1d1a" d="M 638.5,738.5 C 751.255,735.409 863.255,742.909 974.5,761C 1024.64,769.695 1073.64,782.695 1121.5,800C 1133.07,804.45 1144.4,809.45 1155.5,815C 1068.34,813.858 981.171,812.358 894,810.5C 823.663,812 753.329,813.667 683,815.5C 656.337,815.965 629.837,814.131 603.5,810C 571.403,805.536 544.237,791.703 522,768.5C 517.336,762.509 513.17,756.176 509.5,749.5C 512.629,747.978 515.963,747.145 519.5,747C 559.243,743.118 598.91,740.285 638.5,738.5 Z"/></g>
            <g><path style="opacity:1" fill="#3b3b21" d="M 678.5,743.5 C 680.021,768.065 687.687,790.399 701.5,810.5C 662.39,812.302 623.724,809.135 585.5,801C 556.419,793.817 533.753,777.817 517.5,753C 570.973,746.878 624.64,743.712 678.5,743.5 Z"/></g>
            <g><path style="opacity:1" fill="#1e1e11" d="M 678.5,743.5 C 687.167,743.5 695.833,743.5 704.5,743.5C 706.943,767.841 715.276,789.841 729.5,809.5C 720.151,809.483 710.818,809.817 701.5,810.5C 687.687,790.399 680.021,768.065 678.5,743.5 Z"/></g>
            <g><path style="opacity:1" fill="#3b3b21" d="M 704.5,743.5 C 819.058,742.612 932.058,755.112 1043.5,781C 1072.8,788.322 1101.47,797.656 1129.5,809C 1046.67,808.181 963.834,807.015 881,805.5C 830.501,806.802 780.001,808.135 729.5,809.5C 715.276,789.841 706.943,767.841 704.5,743.5 Z"/></g>
          </svg>
        `;
  
        const svgDataUrl = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgString)}`;
        return svgDataUrl;
      };

      const newData = data.map(item => {
        const currentCoord = item.coordinates[0].coord
        const previousCoord = item.coordinates[1].coord
        const deltaX = currentCoord[0] - previousCoord[0];
        const deltaY = currentCoord[1] - previousCoord[1];
        let angleRad = Math.atan2(deltaY, deltaX);
        let angleDeg = angleRad * (180 / Math.PI);
        angleDeg = angleDeg  - 90;
        
        if (angleDeg < 0) {
          angleDeg += 360;
        }

        const matchCourierColor = state.couriers.find(courier => item.courierId === courier.courierId).color;
        item.placemark = getCustomPlacemark(angleDeg, matchCourierColor);
        return item;
      })

      state.couriersLocation = newData;
    },
    setCourierRoute: (state, action) => {
      const data = action.payload;
      
      const newData = data.map(item => {
        const matchCourierColor = state.couriers.find(courier => item.courierId === courier.courierId).color;
        item.color = matchCourierColor;
        return item;
      })

      state.couriersRoute = newData;
    },
    updateCourierLocationAndRoute: (state, action) => {
      const { ...rest } = action.payload;

      return {
        ...state,
        selectedCourierLocationAndRoute: {
          ...state.selectedCourierLocationAndRoute,
          ...rest,
        },
      };
    },

    //Couriers Detail
    sendRequestCouriersDetailGet: (state) => { state.couriersDetailGetStatus = 'pending'; },
    sendRequestCouriersDetailGetSuccess: (state) => { state.couriersDetailGetStatus = 'resolved'; },
    sendRequestCouriersDetailGetError: (state) => { state.couriersDetailGetStatus = 'rejected'; },
    clearCouriersDetailGetStatus: (state) => { state.couriersDetailGetStatus = null; },
    setCouriersDetail: (state, action) => {
      const data = action.payload;
      const sortData = data.sort((a, b) => a.indexNum - b.indexNum)

      state.couriersDetail = sortData;
    },
    sendRequestCourierRouteSave: (state) => { state.courierRouteSaveStatus = 'pending'; },
    sendRequestCourierRouteSaveSuccess: (state) => { state.courierRouteSaveStatus = 'resolved'; },
    sendRequestCourierRouteSaveError: (state) => { state.courierRouteSaveStatus = 'rejected'; },
    clearCourierRouteSaveStatus: (state) => { state.courierRouteSaveStatus = null; },
  
    resetState: (state) => {
      state.couriersLocation = [];
      state.couriersRoute = [];
      state.selectedCourierLocationAndRoute = {showAllCourier: false, locationCourierIds: [], trackCourierIds: []};
      state.detailCouriersShow = false;
      state.currentCourierId = null;
      state.courierDeliveryTrack = [];
      state.selectedOrder = null;
      state.mapMode = 'defaultMode';
    },
  },
});

export const {
  //Form Filters
  setFormFiltersShow,
  updateFormFilterData,

  //MapData
  setCurrentCourierId,
  setLocationsShow,
  setCurrentLocation,
  updateMapDataPoints,
  setSearchQuery,
  setMapAddressLocation,
  setAllEmptylocationsShow,

  setMapMode,
  clearGeocodeAddress,
  setLocationAddressAddedArray,

  selectOrder,
  selectOrderArray,
  selectCourierToAppoint,
  setFirstPlacemarkToGroupOrders,
  setSecondPlacemarkToGroupOrders,
  setPlacemarkDragActivate,
  setPlacemarkDragCancel,
  setSelectedOrderOldCoords,
  setSelectedOrderNewCoords,

  setCouriersShow,
  setDetailCouriersShow,

  //Address Change Geopoint
  sendRequestForAddressChangeGeopoint,
  sendRequestForAddressChangeGeopointSuccess,
  sendRequestForAddressChangeGeopointError,
  sendRequestForAddressChangeGeopointClear,

  //Assign Courier To Addresses
  sendRequestForAssignCourierToAddresses,
  sendRequestForAssignCourierToAddressesSuccess,
  sendRequestForAssignCourierToAddressesError,
  sendRequestForAssignCourierToAddressesClear,

  //Update Address Comment
  sendRequestForUpdateAddressComment,
  sendRequestForUpdateAddressCommentSuccess,
  sendRequestForUpdateAddressCommentError,
  sendRequestForUpdateAddressCommentClear,

  //Update Order Comment
  sendRequestForUpdateOrderComment,
  sendRequestForUpdateOrderCommentSuccess,
  sendRequestForUpdateOrderCommentError,
  sendRequestForUpdateOrderCommentClear,

  //Couriers Geo Location
  sendRequestWhereCouriersGet,
  sendRequestWhereCouriersGetSuccess,
  sendRequestWhereCouriersGetError,
  clearWhereCouriersGetStatus,
  setCouriersLocation,
  setCourierRoute,
  updateCourierLocationAndRoute,

  //Couriers Detail
  sendRequestCouriersDetailGet,
  sendRequestCouriersDetailGetSuccess,
  sendRequestCouriersDetailGetError,
  clearCouriersDetailGetStatus,
  setCouriersDetail,
  sendRequestCourierRouteSave,
  sendRequestCourierRouteSaveSuccess,
  sendRequestCourierRouteSaveError,
  clearCourierRouteSaveStatus,

  resetState,
} = deliveryMapSlice.actions;

export default deliveryMapSlice.reducer;
