import { takeLatest } from 'redux-saga/effects';
import {
  //News
  handleGetNews,
  handleCreateNews,
  handleUpdateNews,
  handleDeleteNews,
} from './kitchenDashboardHandler';
import {
  //News
  sendRequestNewsGet,
  sendRequestNewsCreate,
  sendRequestNewsUpdate,
  sendRequestNewsDelete,
} from '../../../../reducers/utils/kitchenReducers/kitchenDashboardReducer';

export function* kitchenDashboardWatcherSaga() {
  yield takeLatest(sendRequestNewsGet.type, handleGetNews);
  yield takeLatest(sendRequestNewsCreate.type, handleCreateNews);
  yield takeLatest(sendRequestNewsUpdate.type, handleUpdateNews);
  yield takeLatest(sendRequestNewsDelete.type, handleDeleteNews);
}