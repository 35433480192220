import React from 'react';
import styled from 'styled-components';

const Card = ({ className, title, children }) => {
    return (
        <CardContainer className={className}>
            {title && (
                <>
                    {title}
                    <Divider />
                </>
            )}
            {children}
        </CardContainer>
    );
};

export default Card;

const CardContainer = styled.div`
    display: flex;
	flex-direction: column;
	background: #ffffff;
	border-radius: var(--radius-large);
	padding: 32px;
	overflow: hidden;
`
const Divider = styled.hr`
    border: none;
    border-top: 1px solid #EEEEEE;
    margin: 20px 0;
`
