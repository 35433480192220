import React from 'react';
import { useSelector } from 'react-redux';
import Loader from '../../../../components/Loader';
import StatisticsComponent from './components/StatisticsComponent';
// import WelcomeComponent from './components/WelcomeComponent';
import DaysStatistics from './components/DaysStatistics';
import KitchenSelectorComponent from '../../components/KitchenSelectorComponent';
import { Flex, PageContent, PageHeader, Text, Title } from '../../../../core/styles';
import NewsComponent from './components/NewsComponent';
import NewKitchenCreateComponent from './components/NewKitchenCreateComponent';
import MainStats from './components/MainStats';
import NewKitchenSteps from './components/NewKitchenSteps';
import ListProductCard from './components/ListProductCard';

const DashboardPage = () => {
  const { kitchensRequestStatus, kitchens } = useSelector((store) => store.kitchen);

  return (
    <>
      <PageHeader gap='24px' wrap>
          <Flex column flex='2 1' justify='space-between' gap='32px'>

            <Flex justify='space-between' gap='16px'>

              <KitchenSelectorComponent/>

              <NewKitchenCreateComponent/>

            </Flex>

            <MainStats/>
          </Flex>

          <Flex column flex='0 0 300px'>
            <NewsComponent/>
          </Flex>
      </PageHeader>



      <PageContent>

        <NewKitchenSteps/>

        <Flex gap='24px'>
          <Flex column flex='0 0 385px'>
            <Title level={3} style={{marginBottom: '40px'}}>Мои товары</Title>
            <ListProductCard/>
          </Flex>
          <Flex column flex='1 1 auto' style={{width: '100%'}}>
            <Title level={3} style={{marginBottom: '40px'}}>Публикация меню</Title>
            <DaysStatistics/>
          </Flex>
        </Flex>
        
        <Flex style={{marginTop: '50px'}} gap='24px'>
          <Flex column flex='1 1 auto'>
            <StatisticsComponent/>
          </Flex>
          <Flex column flex='0 0 385px' style={{width: '100%'}}>
            <Title level={3} style={{marginBottom: '40px'}}>Рейтинг</Title>
          </Flex>
        </Flex>
      </PageContent>
    </>
  );
};

export default DashboardPage;
