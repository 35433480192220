import apiInstance from '../../../../../api';
import {call, put, select} from 'redux-saga/effects';
import {
  //Kitchen Main Settings
  sendRequestRenameKitchenSuccess,
  sendRequestRenameKitchenError,
  sendRequestCreateKitchenPlashkaSuccess,
  sendRequestCreateKitchenPlashkaError,
  sendRequestDeleteKitchenPlashkaSuccess,
  sendRequestDeleteKitchenPlashkaError,
  sendRequestChangeCityKitchenSuccess,
  sendRequestChangeCityKitchenError,
  sendRequestGetAllKitchenStatusesSuccess,
  sendRequestGetAllKitchenStatusesError,
  setAllKitchenStatuses,
  sendRequestChangeStatusKitchenSuccess,
  sendRequestChangeStatusKitchenError,

  //Kitchen Settings Not Working
  sendRequestKitchenNotWorkingGetSuccess,
  sendRequestKitchenNotWorkingGetError,
  setKitchenNotWorking,
  sendRequestKitchenNotWorkingCreateSuccess,
  sendRequestKitchenNotWorkingCreateError,
  sendRequestKitchenNotWorkingUpdateSuccess,
  sendRequestKitchenNotWorkingUpdateError,
  sendRequestKitchenNotWorkingDeleteSuccess,
  sendRequestKitchenNotWorkingDeleteError,

  //Kitchen Settings Access
  sendRequestkitchenSettingsAccessGetSuccess,
  sendRequestkitchenSettingsAccessGetError,
  setKitchenSettingsAccess,
  sendRequestkitchenSettingsAccessSetSuccess,
  sendRequestkitchenSettingsAccessSetError,
  sendRequestkitchenSettingsAccessDeleteSuccess,
  sendRequestkitchenSettingsAccessDeleteError,

  //Kitchen Contracts
  sendRequestKitchenContractsGetSuccess,
  sendRequestKitchenContractsGetError,
  setKitchenContracts,
  sendRequestKitchenContractsCreateSuccess,
  sendRequestKitchenContractsCreateError,
  sendRequestKitchenContractsUpdateSuccess,
  sendRequestKitchenContractsUpdateError,
  sendRequestKitchenContractsDeleteSuccess,
  sendRequestKitchenContractsDeleteError,

  //Kitchen Contract Commissions
  sendRequestKitchenContractCommissionsGetSuccess,
  sendRequestKitchenContractCommissionsGetError,
  setKitchenContractCommissions,
  sendRequestKitchenContractCommissionsCreateSuccess,
  sendRequestKitchenContractCommissionsCreateError,
  sendRequestKitchenContractCommissionsUpdateSuccess,
  sendRequestKitchenContractCommissionsUpdateError,
  sendRequestKitchenContractCommissionsDeleteSuccess,
  sendRequestKitchenContractCommissionsDeleteError,
} from '../../../../reducers/utils/kitchenReducers/kitchenSettingsReducer';
import { selectCommonState } from '../../../../reducers/utils/commonReducer';

//Kitchen Main Settings
export function* handleRenameKitchen(action) {
  const api = '/kitchens/settings/renameKitchen?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestRenameKitchenSuccess());
    } else {
      yield put(sendRequestRenameKitchenError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestRenameKitchenError());
  }
}

export function* handleCreateKitchenPlashka(action) {
  const api = '/kitchens/settings/creteKitchenPlashka?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  let formData = new FormData();
  formData.append('image', action.payload.image);
  formData.append('data', JSON.stringify( data ));

  try {
    const response = yield call(() => apiInstance.post(fullApi, formData));
    

    if (response.data.result) {
      yield put(sendRequestCreateKitchenPlashkaSuccess());
    } else {
      yield put(sendRequestCreateKitchenPlashkaError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestCreateKitchenPlashkaError());
  }
}

export function* handleDeleteKitchenPlashka(action) {
  const api = '/kitchens/settings/deleteKitchenPlashka?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestDeleteKitchenPlashkaSuccess());
    } else {
      yield put(sendRequestDeleteKitchenPlashkaError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestDeleteKitchenPlashkaError());
  }
}

export function* handleChangeCityKitchen(action) {
  const api = '/kitchens/changeCityKitchen?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestChangeCityKitchenSuccess());
    } else {
      yield put(sendRequestChangeCityKitchenError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestChangeCityKitchenError());
  }
}

export function* handleGetAllKitchenStatuses(action) {
  const api = '/kitchens/getAllKitchenStatuses?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestGetAllKitchenStatusesSuccess());
      yield put(setAllKitchenStatuses(response.data.data.statuses));
    } else {
      yield put(sendRequestGetAllKitchenStatusesError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestGetAllKitchenStatusesError());
  }
}

export function* handleChangeStatusKitchen(action) {
  const api = '/kitchens/changeStatusKitchen?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestChangeStatusKitchenSuccess());
    } else {
      yield put(sendRequestChangeStatusKitchenError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestChangeStatusKitchenError());
  }
}

//Kitchen Settings Not Working
export function* handleKitchenNotWorkingGet(action) {
  const api = '/kitchens/settings/kitchenNotWorking/get?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestKitchenNotWorkingGetSuccess());
      yield put(setKitchenNotWorking(response.data.data.kitchenNotWorking));
    } else {
      yield put(sendRequestKitchenNotWorkingGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestKitchenNotWorkingGetError());
  }
}

export function* handleKitchenNotWorkingCreate(action) {
  const api = '/kitchens/settings/kitchenNotWorking/create?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestKitchenNotWorkingCreateSuccess());
    } else {
      yield put(sendRequestKitchenNotWorkingCreateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestKitchenNotWorkingCreateError());
  }
}

export function* handleKitchenNotWorkingUpdate(action) {
  const api = '/kitchens/settings/kitchenNotWorking/update?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestKitchenNotWorkingUpdateSuccess());
    } else {
      yield put(sendRequestKitchenNotWorkingUpdateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestKitchenNotWorkingUpdateError());
  }
}

export function* handleKitchenNotWorkingDelete(action) {
  const api = '/kitchens/settings/kitchenNotWorking/delete?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestKitchenNotWorkingDeleteSuccess());
    } else {
      yield put(sendRequestKitchenNotWorkingDeleteError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestKitchenNotWorkingDeleteError());
  }
}

//Kitchen Settings Access
export function* handleGetKitchenSettingsAccess(action) {
  const api = '/kitchens/settings/getSettingsByKitchenId?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestkitchenSettingsAccessGetSuccess());
      yield put(setKitchenSettingsAccess(response.data.data.kitchenSettings));
    } else {
      yield put(sendRequestkitchenSettingsAccessGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestkitchenSettingsAccessGetError());
  }
}

export function* handleSetKitchenSettingsAccess(action) {
  const api = '/kitchens/settings/setSettingsByKitchenId?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestkitchenSettingsAccessSetSuccess());
    } else {
      yield put(sendRequestkitchenSettingsAccessSetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestkitchenSettingsAccessSetError());
  }
}

export function* handleDeleteKitchenSettingsAccess(action) {
  const api = '/kitchens/settings/deleteSettingByKitchenId?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestkitchenSettingsAccessDeleteSuccess());
    } else {
      yield put(sendRequestkitchenSettingsAccessDeleteError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestkitchenSettingsAccessDeleteError());
  }
}

//Kitchen Contracts
export function* handleGetKitchenContracts(action) {
  const api = '/kitchen/contract/get?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      const modifyData = response.data.data.contracts.map((item) => ({ ...item, key: item.id }))

      yield put(sendRequestKitchenContractsGetSuccess());
      yield put(setKitchenContracts(modifyData));
    } else {
      yield put(sendRequestKitchenContractsGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestKitchenContractsGetError());
  }
}

export function* handleCreateKitchenContracts(action) {
  const api = '/kitchen/contract/create?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestKitchenContractsCreateSuccess());
    } else {
      yield put(sendRequestKitchenContractsCreateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestKitchenContractsCreateError());
  }
}

export function* handleUpdateKitchenContracts(action) {
  const api = '/kitchen/contract/update?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestKitchenContractsUpdateSuccess());
    } else {
      yield put(sendRequestKitchenContractsUpdateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestKitchenContractsUpdateError());
  }
}

export function* handleDeleteKitchenContracts(action) {
  const api = '/kitchen/contract/delete?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestKitchenContractsDeleteSuccess());
    } else {
      yield put(sendRequestKitchenContractsDeleteError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestKitchenContractsDeleteError());
  }
}

//Kitchen Contract Commissions
export function* handleGetKitchenContractCommissions(action) {
  const api = '/kitchen/commission/get?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestKitchenContractCommissionsGetSuccess());
      yield put(setKitchenContractCommissions(response.data.data));
    } else {
      yield put(sendRequestKitchenContractCommissionsGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestKitchenContractCommissionsGetError());
  }
}

export function* handleCreateKitchenContractCommissions(action) {
  const api = '/kitchen/commission/create?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestKitchenContractCommissionsCreateSuccess());
    } else {
      yield put(sendRequestKitchenContractCommissionsCreateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestKitchenContractCommissionsCreateError());
  }
}

export function* handleUpdateKitchenContractCommissions(action) {
  const api = '/kitchen/commission/update?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestKitchenContractCommissionsUpdateSuccess());
    } else {
      yield put(sendRequestKitchenContractCommissionsUpdateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestKitchenContractCommissionsUpdateError());
  }
}

export function* handleDeleteKitchenContractCommissions(action) {
  const api = '/kitchen/commission/delete?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestKitchenContractCommissionsDeleteSuccess());
    } else {
      yield put(sendRequestKitchenContractCommissionsDeleteError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestKitchenContractCommissionsDeleteError());
  }
}
