import apiInstance from '../../../../../api';
import {call, put, select} from 'redux-saga/effects';
import {
  //News
  sendRequestNewsGetSuccess,
  sendRequestNewsGetError,
  setNews,
  sendRequestNewsCreateSuccess,
  sendRequestNewsCreateError,
  sendRequestNewsUpdateSuccess,
  sendRequestNewsUpdateError,
  sendRequestNewsDeleteSuccess,
  sendRequestNewsDeleteError,
} from '../../../../reducers/utils/kitchenReducers/kitchenDashboardReducer';
import { selectCommonState } from '../../../../reducers/utils/commonReducer';

//News
export function* handleGetNews(action) {
  const api = '/news-admin/get?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestNewsGetSuccess());
      yield put(setNews(response.data.data.newsAdmin));
    } else {
      yield put(sendRequestNewsGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestNewsGetError());
  }
}

export function* handleCreateNews(action) {
  const api = '/news-admin/create?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestNewsCreateSuccess());
    } else {
      yield put(sendRequestNewsCreateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestNewsCreateError());
  }
}

export function* handleUpdateNews(action) {
  const api = '/news-admin/update?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestNewsUpdateSuccess());
    } else {
      yield put(sendRequestNewsUpdateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestNewsUpdateError());
  }
}

export function* handleDeleteNews(action) {
  const api = '/news-admin/delete?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestNewsDeleteSuccess());
    } else {
      yield put(sendRequestNewsDeleteError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestNewsDeleteError());
  }
}
