import apiInstance from '../../../../../api';
import {call, put, select} from 'redux-saga/effects';
import {
  //Races For Days
  sendRequestRacesForDaysSuccess,
  sendRequestRacesForDaysError,
  setRacesForDaysData,
  updateRacesForDaysData,

  //Menu
  sendRequestCreateMenuSuccess,
  sendRequestCreateMenuError,
  sendRequestEnableMenuSuccess,
  sendRequestEnableMenuError,
  sendRequestDisableMenuSuccess,
  sendRequestDisableMenuError,
  
  //Products
  sendRequestMenuAllProductsSuccess,
  sendRequestMenuAllProductsError,
  setMenuAllProductsData,
  updateMenuAllProductsData,
  updateMenuProductDataById,
  sendRequestAddProductInMenuSuccess,
  sendRequestAddProductInMenuError,
  sendRequestEnableProductInMenuSuccess,
  sendRequestEnableProductInMenuError,
  sendRequestDisableProductInMenuSuccess,
  sendRequestDisableProductInMenuError,
  sendRequestUpdateProductInMenuSuccess,
  sendRequestUpdateProductInMenuError,

  //Menu Template
  sendRequestMenuTemplatesSuccess,
  sendRequestMenuTemplatesError,
  setMenuTemplatesData,
  updateSelectedTemplate,
  setLastCreatedTemplateId,
  sendRequestCreateMenuTemplateSuccess,
  sendRequestCreateMenuTemplateError,
  sendRequestUpdateMenuTemplateSuccess,
  sendRequestUpdateMenuTemplateError,
  sendRequestDeleteMenuTemplateSuccess,
  sendRequestDeleteMenuTemplateError,
  sendRequestCopyMenuTemplateSuccess,
  sendRequestCopyMenuTemplateError,
  sendRequestTransferMenuTemplateSuccess,
  sendRequestTransferMenuTemplateError,

  //Menu Template Products
  sendRequestMenuTemplateProductsSuccess,
  sendRequestMenuTemplateProductsError,
  setMenuTemplateProductsData,
  updateMenuTemplateProducts,
  sendRequestAddProductMenuTemplateSuccess,
  sendRequestAddProductMenuTemplateError,
  sendRequestUpdateProductMenuTemplateSuccess,
  sendRequestUpdateProductMenuTemplateError,
  sendRequestRemoveProductMenuTemplateSuccess,
  sendRequestRemoveProductMenuTemplateError,

  //Modifier Groups
  sendRequestModifierGroupsGetSuccess,
  sendRequestModifierGroupsGetError,
  setModifierGroups,
  sendRequestModifierGroupsCreateSuccess,
  sendRequestModifierGroupsCreateError,
  sendRequestModifierGroupsUpdateSuccess,
  sendRequestModifierGroupsUpdateError,
  sendRequestModifierGroupsDeleteSuccess,
  sendRequestModifierGroupsDeleteError,

  //Modifier Groups Products
  sendRequestModifierGroupsProductsGetSuccess,
  sendRequestModifierGroupsProductsGetError,
  setModifierGroupsProducts,
  sendRequestModifierGroupsProductsCreateSuccess,
  sendRequestModifierGroupsProductsCreateError,
  sendRequestModifierGroupsProductUpdateSuccess,
  sendRequestModifierGroupsProductUpdateError,
  sendRequestModifierGroupsProductDeleteSuccess,
  sendRequestModifierGroupsProductDeleteError,
} from '../../../../reducers/utils/kitchenReducers/kitchenMenuReducer';
import { selectCommonState } from '../../../../reducers/utils/commonReducer';

//Races For Days
export function* handleGetRacesForDays(action) {
  const api = '/menu/getRacesForDays?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestRacesForDaysSuccess());
      yield put(setRacesForDaysData(response.data.data.racesForDays));
    } else {
      yield put(sendRequestRacesForDaysError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestRacesForDaysError());
  }
}

//Menu
export function* handleCreateMenu(action) {
  const api = '/menu/create?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestCreateMenuSuccess());
      yield put(updateRacesForDaysData(response.data.data.menu));
    } else {
      yield put(sendRequestCreateMenuError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestCreateMenuError());
  }
}

export function* handleEnableMenu(action) {
  const api = '/menu/enableMenu?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestEnableMenuSuccess());
      yield put(updateMenuAllProductsData({statusMenu: 'publish'}));
    } else {
      yield put(sendRequestEnableMenuError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestEnableMenuError());
  }
}

export function* handleDisableMenu(action) {
  const api = '/menu/disableMenu?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestDisableMenuSuccess());
      yield put(updateMenuAllProductsData({statusMenu: 'draft'}));
    } else {
      yield put(sendRequestDisableMenuError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestDisableMenuError());
  }
}

//Products
export function* handleGetMenuAllProducts(action) {
  const api = '/menu/products?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestMenuAllProductsSuccess());
      yield put(setMenuAllProductsData(response.data.data.products));
    } else {
      yield put(sendRequestMenuAllProductsError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestMenuAllProductsError());
  }
}

export function* handleMenuAddProductInMenu(action) {
  const api = '/menu/addProductInMenu?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestAddProductInMenuSuccess());
      yield put(updateMenuProductDataById({productId: data.products[0].productId, status: 'on', inMenu: true, statusMenu: 'publish'}));
    } else {
      yield put(sendRequestAddProductInMenuError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestAddProductInMenuError());
  }
}

export function* handleMenuEnableProductInMenu(action) {
  const api = '/menu/enableProductInMenu?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestEnableProductInMenuSuccess());
      // yield put(updateMenuProductDataById({productId: data.productId, status: 'on'}));
    } else {
      yield put(sendRequestEnableProductInMenuError());
      yield put(updateMenuProductDataById({productId: data.productId, status: 'off'}));
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestEnableProductInMenuError());
  }
}

export function* handleMenuDisableProductInMenu(action) {
  const api = '/menu/disableProductInMenu?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestDisableProductInMenuSuccess());
      // yield put(updateMenuProductDataById({productId: data.productId, status: 'off'}));
    } else {
      yield put(sendRequestDisableProductInMenuError());
      yield put(updateMenuProductDataById({productId: data.productId, status: 'on'}));
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestDisableProductInMenuError());
  }
}

export function* handleMenuUpdateProductInMenu(action) {
  const api = '/menu/updateProductsInMenu?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestUpdateProductInMenuSuccess());
      yield put(updateMenuProductDataById({
        productId: data.products[0].productId,
        amountForOrder: data.products[0].amountForOrder,
        status: 'on',
        statusMenu: 'publish',
      }));
    } else {
      yield put(sendRequestUpdateProductInMenuError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestUpdateProductInMenuError());
  }
}

//Menu Template
export function* handleGetMenuTemplates(action) {
  const api = '/menu/template/getMenu?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestMenuTemplatesSuccess());
      yield put(setMenuTemplatesData(response.data.data.menuTemplates));
    } else {
      yield put(sendRequestMenuTemplatesError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestMenuTemplatesError());
  }
}

export function* handleCreateMenuTemplate(action) {
  const api = '/menu/template/createMenu?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(setLastCreatedTemplateId(response.data.data.menuTemplate.id));
      yield put(sendRequestCreateMenuTemplateSuccess());
    } else {
      yield put(sendRequestCreateMenuTemplateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestCreateMenuTemplateError());
  }
}

export function* handleUpdateMenuTemplate(action) {
  const api = '/menu/template/updateMenu?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    // 

    if (response.data.result) {
      yield put(sendRequestUpdateMenuTemplateSuccess());
      yield put(updateSelectedTemplate({name: data.name}));
    } else {
      yield put(sendRequestUpdateMenuTemplateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestUpdateMenuTemplateError());
  }
}

export function* handleDeleteMenuTemplate(action) {
  const api = '/menu/template/deleteMenu?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestDeleteMenuTemplateSuccess());
    } else {
      yield put(sendRequestDeleteMenuTemplateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestDeleteMenuTemplateError());
  }
}

export function* handleCopyMenuTemplate(action) {
  const api = '/menu/template/copyMenuTemplate?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestCopyMenuTemplateSuccess());
    } else {
      yield put(sendRequestCopyMenuTemplateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestCopyMenuTemplateError());
  }
}

export function* handleTransferMenuTemplate(action) {
  const api = '/menu/template/transferFromTemplateInMenu?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestTransferMenuTemplateSuccess());
    } else {
      yield put(sendRequestTransferMenuTemplateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestTransferMenuTemplateError());
  }
}

//Menu Template Products
export function* handleGetMenuTemplateProducts(action) {
  const api = '/menu/template/getMenuProducts?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestMenuTemplateProductsSuccess());
      yield put(setMenuTemplateProductsData(response.data.data.products));
    } else {
      yield put(sendRequestMenuTemplateProductsError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestMenuTemplateProductsError());
  }
}

export function* handleAddMenuTemplateProduct(action) {
  const api = '/menu/template/addProductInMenu?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestAddProductMenuTemplateSuccess());
      yield put(updateMenuTemplateProducts({
        productId: data.products[0].productId,
        amountForOrder: data.products[0].amountForOrder,
        status: 'on',
        inMenu: true
      }));
    } else {
      yield put(sendRequestAddProductMenuTemplateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestAddProductMenuTemplateError());
  }
}

export function* handleUpdateMenuTemplateProduct(action) {
  const api = '/menu/template/updateProductInMenu?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestUpdateProductMenuTemplateSuccess());
      yield put(updateMenuTemplateProducts({
        productId: data.productId,
        status: data.status,
        amountForOrder: data.amountForOrder
      }));
    } else {
      yield put(sendRequestUpdateProductMenuTemplateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestUpdateProductMenuTemplateError());
  }
}

export function* handleRemoveMenuTemplateProduct(action) {
  const api = '/menu/template/removeProductInMenu?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestRemoveProductMenuTemplateSuccess());
      yield put(updateMenuTemplateProducts({productId: data.productId, status: 'off'}));
    } else {
      yield put(sendRequestRemoveProductMenuTemplateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestRemoveProductMenuTemplateError());
  }
}

//Modifier Groups
export function* handleGetModifierGroups(action) {
  const api = '/products/modifiersGroups/getModifiersGroupsByKitchenId?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestModifierGroupsGetSuccess());
      yield put(setModifierGroups(response.data.data.modifiersGroups));
    } else {
      yield put(sendRequestModifierGroupsGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestModifierGroupsGetError());
  }
}

export function* handleCreateModifierGroups(action) {
  const api = '/products/modifiersGroups/create?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestModifierGroupsCreateSuccess());
    } else {
      yield put(sendRequestModifierGroupsCreateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestModifierGroupsCreateError());
  }
}

export function* handleUpdateModifierGroups(action) {
  const api = '/products/modifiersGroups/update?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestModifierGroupsUpdateSuccess());
    } else {
      yield put(sendRequestModifierGroupsUpdateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestModifierGroupsUpdateError());
  }
}

export function* handleDeleteModifierGroups(action) {
  const api = '/products/modifiersGroups/delete?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestModifierGroupsDeleteSuccess());
    } else {
      yield put(sendRequestModifierGroupsDeleteError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestModifierGroupsDeleteError());
  }
}

//Modifier Groups Products
export function* handleGetModifierGroupsProducts(action) {
  const api = '/products/modifiersGroupsProducts/getByModifierGroupId?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestModifierGroupsProductsGetSuccess());
      yield put(setModifierGroupsProducts(response.data.data.modifiersGroups));
    } else {
      yield put(sendRequestModifierGroupsProductsGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestModifierGroupsProductsGetError());
  }
}

export function* handleCreateModifierGroupsProduct(action) {
  const api = '/products/modifiersGroupsProducts/create?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestModifierGroupsProductsCreateSuccess());
    } else {
      yield put(sendRequestModifierGroupsProductsCreateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestModifierGroupsProductsCreateError());
  }
}

export function* handleUpdateModifierGroupsProduct(action) {
  const api = '/products/modifiersGroupsProducts/update?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestModifierGroupsProductUpdateSuccess());
    } else {
      yield put(sendRequestModifierGroupsProductUpdateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestModifierGroupsProductUpdateError());
  }
}

export function* handleDeleteModifierGroupsProduct(action) {
  const api = '/products/modifiersGroupsProducts/delete?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestModifierGroupsProductDeleteSuccess());
    } else {
      yield put(sendRequestModifierGroupsProductDeleteError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestModifierGroupsProductDeleteError());
  }
}
