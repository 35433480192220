import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import MainLayout from './layouts/MainLayout';
import DevComponent from './layouts/DevComponent';

import AuthPage from '../modules/auth/AuthPage';
// import MainModule from '../modules/mainModule/MainModule';
import KitchenModule from '../modules/kitchenModule/KitchenModule';
import SettingsModule from '../modules/settingsModule/SettingsModule';
import DeliveryModule from '../modules/deliveryModule/DeliveryModule';
import ClientsModule from '../modules/clientsModule/ClientsModule';
import FinanceModule from '../modules/financeModule/FinanceModule';
import PartnersModule from '../modules/partnersModule/PartnersModule';
import SupportModule from '../modules/supportModule/SupportModule';
import ModerationModule from '../modules/moderationModule/ModerationModule';
import ServicesModule from '../modules/servicesModule/ServicesModule';
import DocumentationModule from '../modules/documentationModule/DocumentationModule';

import DashboardPage from '../modules/kitchenModule/pages/dashboardPage/DashboardPage';
import MenuPublicationPage from '../modules/kitchenModule/pages/menuPages/menuPublicationPage/MenuPublicationPage';
import MenuTemplatesPage from '../modules/kitchenModule/pages/menuPages/menuTemplatesPage/MenuTemplatesPage';
import DeliveryServicesPage from '../modules/deliveryModule/pages/deliveryServicesPage/DeliveryServicesPage';
import DeliveryMapPage from '../modules/deliveryModule/pages/deliveryMapPage/DeliveryMapPage';
import RightsManagementPage from '../modules/servicesModule/pages/rightsManagementPage/RightsManagementPage';
import ComponentsDirectoryPage from '../modules/servicesModule/pages/сomponentsDirectoryPage/ComponentsDirectoryPage';
import RoleAccessPage from '../modules/servicesModule/pages/roleAccessPage/RoleAccessPage';
import MethodsDirectoryPage from '../modules/servicesModule/pages/methodsDirectoryPage/MethodsDirectoryPage';
import RoleMethodsPage from '../modules/servicesModule/pages/roleMethodsPage/RoleMethodsPage';

import AdminPanelDocsPage from '../modules/documentationModule/pages/AdminPanelDocsPage';
import SiteDocsPage from '../modules/documentationModule/pages/SiteDocsPage';
import CourierAppDocsPage from '../modules/documentationModule/pages/CourierAppDocsPage';
import OrganizationsCatalogPage from '../modules/settingsModule/pages/organizationsCatalogPage/OrganizationsCatalogPage';
import CitiesCatalogPage from '../modules/settingsModule/pages/citiesCatalogPage/CitiesCatalogPage';
import DeliveryServiceDetailComponent from '../modules/deliveryModule/pages/deliveryServicesPage/components/DeliveryServiceDetailComponent';
import RaceDetailComponent from '../modules/deliveryModule/pages/deliveryServicesPage/components/deliveryServiceDetail/races/RaceDetailComponent';
import CourierDetailComponent from '../modules/deliveryModule/pages/deliveryServicesPage/components/deliveryServiceDetail/couriers/CourierDetailComponent';
import ContractsPage from '../modules/kitchenModule/pages/contractsPage/ContractsPage';
import MenuModifiersPage from '../modules/kitchenModule/pages/menuPages/menuModifiers/MenuModifiersPage';
import AssortmentPage from '../modules/kitchenModule/pages/assortmentPage/AssortmentPage';
import ModifiersDetailComponent from '../modules/kitchenModule/pages/menuPages/menuModifiers/components/ModifiersDetailComponent';
import ClientsSearchPage from '../modules/clientsModule/pages/clientsSearchPage/ClientsSearchPage';
import ClientDetailPage from '../modules/clientsModule/pages/clientDetailPage/ClientDetailPage';
import ClientsNotificationsPage from '../modules/clientsModule/pages/clientsNotifications/ClientsNotificationsPage';
import EntitySettingsPage from '../modules/servicesModule/pages/entitySettingsPage/EntitySettingsPage';
import SettingsPage from '../modules/kitchenModule/pages/settingsPage/SettingsPage';
import OrdersPage from '../modules/kitchenModule/pages/ordersPage/OrdersPage';
import FinancePage from '../modules/kitchenModule/pages/financePage/FinancePage';
import ComplaintPage from '../modules/kitchenModule/pages/complaintPage/ComplaintPage';
import WalletsPage from '../modules/financeModule/pages/walletsPage/WalletsPage';
import PromocodesPage from '../modules/financeModule/pages/promocodesPage/PromocodesPage';
import NotificationlogsPage from '../modules/servicesModule/pages/logsPages/notificationlogsPage/NotificationlogsPage';
import withMobileDetection from './layouts/withMobileDetection';
import CorpclientsPage from '../modules/financeModule/pages/corpclientsPage/CorpclientsPage';
import AddressesSearchPage from '../modules/clientsModule/pages/addressesSearchPage/AddressesSearchPage';
import FinancialSettlementPage from '../modules/moderationModule/pages/financialSettlementPage/FinancialSettlementPage';
import { checkAccess } from './utils';
import AccessDenied from '../components/AccessDenied ';

const Router = () => {
  const { isAuth } = useSelector((store) => store.auth);

  const DeliveryMapPageWithMobileDetection = withMobileDetection(DeliveryMapPage);

  return (
    <Routes>
      <Route path="/auth" element={<AuthPage/>}/>
      <Route element={<AuthRoute isAuth={isAuth}><MainLayout/></AuthRoute>}>
        <Route path="/kitchens" element={<AccessRoute><KitchenModule/></AccessRoute>}/>
        <Route path="/dashboard" element={<AccessRoute><KitchenModule><DashboardPage/></KitchenModule></AccessRoute>}/>
        <Route path="/assortment" element={<AccessRoute><KitchenModule><AssortmentPage/></KitchenModule></AccessRoute>}/>
        <Route path="/kitchenfinance" element={<AccessRoute><KitchenModule><FinancePage/></KitchenModule></AccessRoute>}/>
        <Route path="/reviews" element={<AccessRoute><KitchenModule><DevComponent page={'Отзывы'}/></KitchenModule></AccessRoute>}/>
        <Route path="/complaint" element={<AccessRoute><KitchenModule><ComplaintPage/></KitchenModule></AccessRoute>}/>
        <Route path="/complex" element={<AccessRoute><KitchenModule><DevComponent page={'Комплексные товары'}/></KitchenModule></AccessRoute>}/>
        <Route path="/assembly" element={<AccessRoute><KitchenModule><DevComponent page={'Сборка товаров'}/></KitchenModule></AccessRoute>}/>
        <Route path="/orders" element={<AccessRoute><KitchenModule><OrdersPage/></KitchenModule></AccessRoute>}/>
        <Route path="/menupublication" element={<AccessRoute><KitchenModule><MenuPublicationPage/></KitchenModule></AccessRoute>}/>
        <Route path="/menulayouts" element={<AccessRoute><KitchenModule><MenuTemplatesPage/></KitchenModule></AccessRoute>}/>
        <Route path="/menumodifiers" element={<AccessRoute><KitchenModule><MenuModifiersPage/></KitchenModule></AccessRoute>}/>
        <Route path="/menumodifiers/:id" element={<AccessRoute><KitchenModule><ModifiersDetailComponent/></KitchenModule></AccessRoute>}/>
        <Route path="/kitchensettings" element={<AccessRoute><KitchenModule><SettingsPage/></KitchenModule></AccessRoute>}/>
        <Route path="/kitchencontracts" element={<AccessRoute><KitchenModule><ContractsPage/></KitchenModule></AccessRoute>}/>
        <Route path="settings" element={<AccessRoute><SettingsModule/></AccessRoute>}/>
        <Route path="settings/main" element={<AccessRoute><DevComponent page={'Основные настройки'}/></AccessRoute>}/>
        <Route path="settings/stocks" element={<AccessRoute><DevComponent page={'Акции'}/></AccessRoute>}/>
        <Route path="settings/notification" element={<AccessRoute><DevComponent page={'Стартовые уведомления'}/></AccessRoute>}/>
        <Route path="settings/pointsofsale" element={<AccessRoute><DevComponent page={'Точки продаж'}/></AccessRoute>}/>
        <Route path="settings/additionalsettings" element={<AccessRoute><DevComponent page={'Дополнительные настройки'}/></AccessRoute>}/>
        <Route path="settings/contracts" element={<AccessRoute><DevComponent page={'Договоры'}/></AccessRoute>}/>
        <Route path="settings/organizationscatalog" element={<AccessRoute><OrganizationsCatalogPage/></AccessRoute>}/>
        <Route path="settings/citiescatalog" element={<AccessRoute><CitiesCatalogPage/></AccessRoute>}/>
        <Route path="delivery" element={<AccessRoute><DeliveryModule/></AccessRoute>}/>
        <Route path="delivery/services" element={<AccessRoute><DeliveryServicesPage/></AccessRoute>}/>
        <Route path="delivery/services/:serviceId" element={<AccessRoute><DeliveryServiceDetailComponent/></AccessRoute>}/>
        <Route path="delivery/services/:serviceId/races/:raceId" element={<AccessRoute><RaceDetailComponent/></AccessRoute>}/>
        <Route path="delivery/services/:serviceId/couriers/:courierId" element={<AccessRoute><CourierDetailComponent/></AccessRoute>}/>
        <Route path="delivery/map" element={<AccessRoute><DeliveryModule><DeliveryMapPageWithMobileDetection/></DeliveryModule></AccessRoute>}/>
        <Route path="delivery/managment" element={<AccessRoute><DevComponent page={'Управления курьерами'}/></AccessRoute>}/>
        <Route path="clients" element={<AccessRoute><ClientsModule/></AccessRoute>}/>
        <Route path="clients/search" element={<AccessRoute><ClientsSearchPage/></AccessRoute>}/>
        <Route path="clients/search/:phone" element={<AccessRoute><ClientDetailPage/></AccessRoute>}/>
        <Route path="clients/notifications" element={<AccessRoute><ClientsNotificationsPage/></AccessRoute>}/>
        <Route path="clients/addresses" element={<AccessRoute><ClientsModule><AddressesSearchPage/></ClientsModule></AccessRoute>}/>
        <Route path="finance" element={<AccessRoute><FinanceModule/></AccessRoute>}/>
        <Route path="finance/wallets" element={<AccessRoute><FinanceModule><WalletsPage/></FinanceModule></AccessRoute>}/>
        <Route path="finance/promocodes" element={<AccessRoute><FinanceModule><PromocodesPage/></FinanceModule></AccessRoute>}/>
        <Route path="finance/corpclients" element={<AccessRoute><FinanceModule><CorpclientsPage/></FinanceModule></AccessRoute>}/>
        <Route path="partners" element={<AccessRoute><PartnersModule/></AccessRoute>}/>
        <Route path="partners/statistics" element={<AccessRoute><DevComponent page={'Статистика по всем кухням'}/></AccessRoute>}/>
        <Route path="partners/financialreports" element={<AccessRoute><DevComponent page={'Финансовые отчёты'}/></AccessRoute>}/>
        <Route path="partners/reports" element={<AccessRoute><DevComponent page={'Отчеты'}/></AccessRoute>}/>
        <Route path="support" element={<AccessRoute><SupportModule/></AccessRoute>}/>
        <Route path="moderation" element={<AccessRoute><ModerationModule/></AccessRoute>}/>
        <Route path="moderation/moderation" element={<AccessRoute><ModerationModule><DevComponent page={'Модерация товаров'}/></ModerationModule></AccessRoute>}/>
        <Route path="moderation/financialsettlement" element={<AccessRoute><ModerationModule><FinancialSettlementPage/></ModerationModule></AccessRoute>}/>
        <Route path="services" element={<AccessRoute><ServicesModule/></AccessRoute>}/>
        <Route path="services/rightsmanagement" element={<AccessRoute><RightsManagementPage/></AccessRoute>}/>
        <Route path="services/componentsdirectory" element={<AccessRoute><ComponentsDirectoryPage/></AccessRoute>}/>
        <Route path="services/roleaccess" element={<AccessRoute><RoleAccessPage/></AccessRoute>}/>
        <Route path="services/methodsdirectory" element={<AccessRoute><MethodsDirectoryPage/></AccessRoute>}/>
        <Route path="services/rolemethods" element={<AccessRoute><RoleMethodsPage/></AccessRoute>}/>
        <Route path="services/entitysettings" element={<AccessRoute><EntitySettingsPage/></AccessRoute>}/>
        <Route path="services/notificationlogs" element={<AccessRoute><NotificationlogsPage/></AccessRoute>}/>
        <Route path="documentation" element={<AccessRoute><DocumentationModule/></AccessRoute>}/>
        <Route path="documentation/adminpaneldocs" element={<AccessRoute><AdminPanelDocsPage/></AccessRoute>}/>
        <Route path="documentation/sitedocs" element={<AccessRoute><SiteDocsPage/></AccessRoute>}/>
        <Route path="documentation/courierappdocs" element={<AccessRoute><CourierAppDocsPage/></AccessRoute>}/>
        <Route path="*" element={<DevComponent page={'404'}/>}/>
      </Route>
    </Routes>
  );
}

export default Router;

function AuthRoute({ isAuth, children }) {
  if (!isAuth) {
    return <Navigate to="/auth" replace/>;
  } 

  return <>{children}</>;
}

function AccessRoute({ children }) {
  // const { accessComponentsForUser } = useSelector((store) => store.services);
  // const location = useLocation();
  // const path = location.pathname;
  // const cleanedPath = path
  //   .split('/')
  //   .filter(Boolean)
  //   .slice(0, 2)
  //   .join('/');

  // if (!checkAccess(accessComponentsForUser, cleanedPath)) {
  //   return <AccessDenied/>
  // } 

  return <>{children}</>;
}
