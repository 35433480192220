import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  sendRequestNewsGet,
  sendRequestNewsCreate,
  sendRequestNewsUpdate,
  sendRequestNewsDelete,
} from '../../../../../redux/reducers/utils/kitchenReducers/kitchenDashboardReducer';
import { Button, Modal, Form, Input, Popconfirm } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Flex, Text, Title } from '../../../../../core/styles';
import styled from 'styled-components';
import { checkAccess } from '../../../../../core/utils';
import moment from 'moment';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from '../../../../../components/Card';

const { TextArea } = Input;

const NewsComponent = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const { accessComponentsForUser } = useSelector((store) => store.services);
  const {
    news,
    newsCreateRequestStatus,
    newsUpdateRequestStatus,
    newsDeleteRequestStatus,
  } = useSelector((store) => store.kitchenDashboard);
  const dispatch = useDispatch();
  const [ isCreate, setIsCreate] = useState(false);
  const [ currentPost, setCurrentPost] = useState(null);
  const [form] = Form.useForm();
  const isAdmin = checkAccess(accessComponentsForUser, 'moderation');

  const settings = {
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    centerPadding: '60px',
    autoplay: false,
    touchThreshold: 100,
    adaptiveHeight: false,
    fade: true,
    draggable: false,
    swipe: false,
    nextArrow: <NextArrow/>,
    prevArrow: <PrevArrow/>,
    dots: true,
};

  useEffect(() => {
    if (isAuth) {
      dispatch(sendRequestNewsGet({ token: localStorage.getItem('token') }));
    }
  }, [isAuth]);

  useEffect(() => {
    if (
      newsCreateRequestStatus === 'resolved' ||
      newsUpdateRequestStatus === 'resolved' ||
      newsDeleteRequestStatus === 'resolved'
    ) {
      dispatch(sendRequestNewsGet({ token: localStorage.getItem('token') }));
    }
  }, [
    newsCreateRequestStatus,
    newsUpdateRequestStatus,
    newsDeleteRequestStatus,
  ]);

  const handleCancel = () => {
    setIsCreate(false);
    setCurrentPost(null);
    form.resetFields();
  };

  const handleSubmit = (values) => {
    const requestData = {
      token: localStorage.getItem('token'),
      title: values.title,
      body: values.body,
    }

    if (currentPost) {
      dispatch(sendRequestNewsUpdate({...requestData, newsAdminId: currentPost.id}));
    } else {
      dispatch(sendRequestNewsCreate(requestData));
    }

    setIsCreate(false);
    form.resetFields();
  };

  const handleCreate = () => {
    setCurrentPost(null);
    setIsCreate(true);
  }

  const handleUpdate = (post) => {
    setCurrentPost(post);
    setIsCreate(true);
    form.setFieldsValue(post);
  }

  const handleDelete = (id) => {
    const requestData = {
      token: localStorage.getItem('token'),
      newsAdminId: id,
    }

    dispatch(sendRequestNewsDelete(requestData));
    setIsCreate(false);
    form.resetFields();
  }

  return (
    <>
      <NewsCard>
        {isAdmin && 
          <Flex justify='flex-end'>
            <Button type='primary' shape="circle" icon={<PlusOutlined/>} onClick={handleCreate}/>
          </Flex>
        }
        <div style={{height: '100%'}}>
          <Slider style={{marginBottom: '20px'}} {...settings}>
            {news &&
              news
                .slice()
                .sort((a, b) => new Date(b.created) - new Date(a.created))
                .slice(0, 8)
                .map(post => (
                  <div key={post.id}>
                    <News key={post.id} post={post} isAdmin={isAdmin} onUpdate={handleUpdate} onDelete={handleDelete} />
                  </div>
                ))
            }
          </Slider>
        </div>
      </NewsCard>

      <Modal
        title="Создание новости"
        open={isCreate}
        onCancel={handleCancel}
        footer={null}
      >
        <Form style={{ width: '100%' }} form={form} name='deliveryService' layout='vertical' onFinish={handleSubmit}>
          <Form.Item label='Заголовок' name='title' rules={[{ required: true, message: 'Введите название' }]}>
            <Input placeholder='Заголовок'/>
          </Form.Item>
          <Form.Item label='Текст' name='body' rules={[{ required: true, message: 'Введите текст' }]}>
            <TextArea rows={2} placeholder='Текст новости'/>
          </Form.Item>
          <Button type='primary' htmlType='submit' style={{ display: 'flex', marginLeft: 'auto' }}>
            {currentPost !== null ? 'сохранить' : 'Создать'}
          </Button>
        </Form>
      </Modal>
    </>
  );
}

export default NewsComponent;

const NextArrow = ({ className, onClick }) => (
  <div className={className} style={{ top: '100%', right: 0, zIndex: 1 }} onClick={onClick}>
    <svg width="23" height="8" viewBox="0 0 23 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.3536 3.64644C22.5488 3.84171 22.5488 4.15829 22.3536 4.35355L19.1716 7.53553C18.9763 7.73079 18.6597 7.73079 18.4645 7.53553C18.2692 7.34027 18.2692 7.02369 18.4645 6.82843L21.2929 4L18.4645 1.17157C18.2692 0.976309 18.2692 0.659727 18.4645 0.464464C18.6597 0.269202 18.9763 0.269202 19.1716 0.464464L22.3536 3.64644ZM-4.37112e-08 3.5L22 3.5L22 4.5L4.37112e-08 4.5L-4.37112e-08 3.5Z" fill="#2C2E32"/>
    </svg>
  </div>
);

const PrevArrow = ({ className, onClick }) => (
  <div className={className} style={{ top: '100%', left: 0, zIndex: 1 }} onClick={onClick}>
    <svg width="23" height="8" viewBox="0 0 23 8" fill="none" xmlns="http://www.w3.org/2000/svg" transform="rotate(180)">
      <path d="M22.3536 3.64644C22.5488 3.84171 22.5488 4.15829 22.3536 4.35355L19.1716 7.53553C18.9763 7.73079 18.6597 7.73079 18.4645 7.53553C18.2692 7.34027 18.2692 7.02369 18.4645 6.82843L21.2929 4L18.4645 1.17157C18.2692 0.976309 18.2692 0.659727 18.4645 0.464464C18.6597 0.269202 18.9763 0.269202 19.1716 0.464464L22.3536 3.64644ZM-4.37112e-08 3.5L22 3.5L22 4.5L4.37112e-08 4.5L-4.37112e-08 3.5Z" fill="#2C2E32"/>
    </svg>
  </div>
);

const News = ({ post, isAdmin, onUpdate, onDelete }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const handleClickUpdate = () => {
    onUpdate(post);
  };

  const handleClickDelete = () => {
    onDelete(post.id);
  };

  return (
    <Flex style={{margin: '10px 0'}} column gap='16px'>
      <Flex justify='space-between' gap='16px'>
        <Title level={3}>{post.title}</Title>
        <Flex column align='flex-end' flex='0 0 fit-content'>
          <Text size='12px' color='#B2B2B2'>{moment(post.created).format('DD-MM-YYYY')}</Text>
          <Text size='12px' color='#B2B2B2'>{moment(post.created).format('HH:mm')}</Text>
        </Flex>
      </Flex>
      <Flex style={{minHeight: '64px'}}>
        <Text>
          {post.body.length > 100 ?
            <>
              {expanded ? post.body : `${post.body.slice(0, 100)}...`}
              {expanded ? (
                <Text link onClick={toggleExpanded}> cвернуть</Text>
              ) : (
                <Text link onClick={toggleExpanded}> читать полностью</Text>
              )}
            </>
            :
            <>
              {post.body}
            </>
          }
        </Text>
      </Flex>

      {isAdmin && 
        <Flex
          justify='flex-end'
          gap='10px'
        >
          <Button onClick={handleClickUpdate}>Редактировать</Button>
          <Popconfirm
            title="Удаление поста!"
            description={`Вы действительно хотите удалить пост?`}
            okText="Да"
            cancelText="Нет"
            placement="left"
            onConfirm={handleClickDelete}
          >
            <Button danger>Удалить</Button>
          </Popconfirm>
        </Flex>
      }
    </Flex>
  );
};

const NewsCard = styled(Card)`
  width: 300px;
  height: 100%;
`