import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { sendRequestCorpclientsContractsGet } from '../../../../redux/reducers/utils/financeReducers/financeCorpclientsReducer';
import { sendRequestClientsGet } from '../../../../redux/reducers/utils/clientsReducers/clientsSearchReducer';
import { sendRequestAddressesSearch } from '../../../../redux/reducers/utils/clientsReducers/addressesSearchReducer';
import {
  sendRequestWalletsSearch,
  setCurrentPage,
  sendRequestUploadWallets,
  sendRequestWalletCreate,
  sendRequestWalletUpdate,
  sendRequestWalletsStatusesGet,
  sendRequestTransactionsSearch,
  setCurrentTransactionsPage,
  sendRequestTransactionCreate,
  clearUploadData,
} from '../../../../redux/reducers/utils/financeReducers/financeWalletsReducer';
import { Typography, Flex, Divider } from 'antd';
import Loader from '../../../../components/Loader';
import WalletCreateComponent from './components/WalletCreateComponent';
import WalletsFiltersComponent from './components/WalletsFiltersComponent';
import WalletDrawerComponent from './components/WalletDrawerComponent';
import WalletsTableComponent from './components/WalletsTableComponent';
import WalletsUploadComponent from './components/WalletsUploadComponent';

const { Title } = Typography;

const WalletsPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isAuth } = useSelector(state => state.auth);
  const { clients } = useSelector((store) => store.clientsSearch);
  const { addresses } = useSelector((store) => store.addressesSearch);
  const { corpclientsContracts } = useSelector((store) => store.financeCorpclients);
  const {
    walletsSearchRequestStatus,
    wallets,
    totalWallets,
    currentPage,
    walletCreateRequestStatus,
    walletUpdateRequestStatus,
    uploadInfo,
    uploadStatus,
    transactionsSearchRequestStatus,
    transactions,
    totalTransactions,
    currentTransactionsPage,
    transactionCreateRequestStatus,
  } = useSelector(state => state.financeWallets);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentWallet, setCurrentWallet] = useState(null);
  const [searchData, setSearchData] = useState({});
  const [searchTransactionData, setSearchTransactionData] = useState({});
  
  const requestData = {
    token: localStorage.getItem('token'),
    offset: (currentPage - 1) * 20,
    limit: 20,
  }

  const requestTransactionData = {
    token: localStorage.getItem('token'),
    offset: (currentTransactionsPage - 1) * 20,
    limit: 20,
  }

  const corpclientsOptions = [
    { value: 0, label: 'Без договора' },
    ...corpclientsContracts.map((item) => ({
      ...item,
      value: item.corporatContractId,
      label: `${item.contractNumber} от ${item.contractDate}`,
    })),
  ]

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has('walletId')) {
      const walletId = searchParams.get('walletId');
      dispatch(sendRequestWalletsSearch({...requestData, walletId: walletId}));
    } else if (isAuth) {
      dispatch(sendRequestWalletsSearch(requestData))
      dispatch(sendRequestCorpclientsContractsGet({...requestData, offset: 0, limit: 10000}));
    }
  }, [isAuth, location]);

  useEffect(() => {
    if (wallets.length === 1) {
      setIsDrawerOpen(true);
      setCurrentWallet(wallets[0]);
      dispatch(sendRequestTransactionsSearch({...requestTransactionData, offset: 0, walletId: wallets[0].walletId }));
    }
  }, [wallets]);

  useEffect(() => {
    if (
      walletCreateRequestStatus === 'resolved' ||
      walletUpdateRequestStatus === 'resolved'
    ) {
      dispatch(sendRequestWalletsSearch(requestData))
    }
  }, [
    walletCreateRequestStatus,
    walletUpdateRequestStatus,
  ]);

  useEffect(() => {
    if (transactionCreateRequestStatus === 'resolved' && currentWallet) {
      dispatch(sendRequestTransactionsSearch({...requestTransactionData, offset: 0, walletId: currentWallet.walletId }));
      dispatch(sendRequestWalletsSearch(requestData));
    }
  }, [transactionCreateRequestStatus]);

  useEffect(() => {
    if (transactionCreateRequestStatus === 'resolved' && wallets.length > 0 && currentWallet) {
      setCurrentWallet(wallets.find(wallet => wallet.id === currentWallet.id));
    }
  }, [wallets]);
  

  const handleSearch = (data) => {
    setSearchData(data)
    dispatch(setCurrentPage(1));
    dispatch(sendRequestWalletsSearch({...requestData, ...data, offset: 0 }))
  }

  const handleOpenDrawer = (record) => {
    setIsDrawerOpen(true);
    setCurrentWallet(record);
    dispatch(setCurrentTransactionsPage(1));
    dispatch(sendRequestTransactionsSearch({...requestTransactionData, offset: 0, walletId: record.walletId }));
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setCurrentWallet(null);
  };

  const handleUploadWallets = (data) => {
    console.log(data);
    dispatch(sendRequestUploadWallets({token: localStorage.getItem('token'), ...data}));
  }

  const handleCancelUploadWallets = () => {
    dispatch(clearUploadData());
  }

  const handleCreateWallet = (data) => {
    dispatch(sendRequestWalletCreate({token: localStorage.getItem('token'), ...data}));
  }

  const handleUpdateWallet = (data) => {
    console.log(data);
    dispatch(sendRequestWalletUpdate({token: localStorage.getItem('token'), ...data}));
    setIsDrawerOpen(false);
    setCurrentWallet(null);
  }

  const handleClientsSearch = (value) => {
    const requestData = {
      token: localStorage.getItem('token'),
      phone: value,
      offset: 0,
      limit: 50,
    }

    dispatch(sendRequestClientsGet(requestData));
  }
  
  const handleAddressesSearch = (value) => {
    const requestData = {
      token: localStorage.getItem('token'),
      addressId: value,
      offset: 0,
      limit: 50,
    }

    dispatch(sendRequestAddressesSearch(requestData));
  }

  const handlePageChange = (page) => {
    dispatch(setCurrentPage(page));
    dispatch(sendRequestWalletsSearch({...requestData, ...searchData, offset: (page - 1) * 20 }));
  }

  const handleSearchTransaction = (data) => {
    console.log(data);
    setSearchTransactionData(data);
    dispatch(setCurrentTransactionsPage(1));
    dispatch(sendRequestTransactionsSearch({...requestTransactionData, ...data, offset: 0 }));
  }

  const handleCreateTransaction = (data) => {
    console.log(data);
    dispatch(sendRequestTransactionCreate({token: localStorage.getItem('token'), ...data}));
  }

  const handleTransactionsPageChange = (page) => {
    dispatch(setCurrentTransactionsPage(page));
    dispatch(sendRequestTransactionsSearch({...requestTransactionData, ...searchTransactionData, offset: (page - 1) * 20, walletId: currentWallet.walletId }));
  }

  return (
    <>
      {(
        walletsSearchRequestStatus === 'pending'
      ) && <Loader/>}

      <Title level={2}>Кошельки</Title>

      <WalletsFiltersComponent
        totalWallets={totalWallets}
        onSearch={handleSearch}
      />

      <Divider/>
      
      <Flex justify='flex-end' style={{marginBottom: '20px'}} gap={16}>
        <WalletCreateComponent
          corpclientsOptions={corpclientsOptions}
          onSubmit={handleCreateWallet}
        />
        <WalletsUploadComponent
          corpclientsOptions={corpclientsOptions}
          uploadInfo={uploadInfo}
          uploadStatus={uploadStatus}
          onSubmit={handleUploadWallets}
          onCancel={handleCancelUploadWallets}
        />
      </Flex>
      
      <WalletsTableComponent
        dataSource={wallets}
        currentWallet={currentWallet}
        totalWallets={totalWallets}
        currentPage={currentPage}
        onRowClick={handleOpenDrawer}
        onChange={handlePageChange}
      />

      <WalletDrawerComponent
        isDrawerOpen={isDrawerOpen}
        onClose={handleCloseDrawer}
        record={currentWallet}
        corpclientsOptions={corpclientsOptions}
        clients={clients}
        addresses={addresses}
        transactions={transactions}
        totalTransactions={totalTransactions}
        currentTransactionsPage={currentTransactionsPage}
        onUpdateWallet={handleUpdateWallet}
        onSearchClients={handleClientsSearch}
        onSearchAddresses={handleAddressesSearch}
        onSearchTransaction={handleSearchTransaction}
        onCreateTransaction={handleCreateTransaction}
        onChange={handleTransactionsPageChange}
      />
    </>
  );
};

export default WalletsPage;
