import React, { useState, useEffect } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Layout, Flex, Typography, Drawer, Button, FloatButton } from 'antd';
import {
  MenuOutlined,
  CloseOutlined,
  QuestionOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import SiderMenuComponent from '../../components/SiderMenuComponent.jsx';
import HeaderMenuComponent from '../../components/HeaderMenuComponent.jsx';
import HeaderProfileDropdownComponent from '../../components/HeaderProfileDropdownComponent.jsx';
import HeaderTitleComponent from '../../components/HeaderTitleComponent.jsx';

import { ReactComponent as IconLogo } from '../../assets/icons/icon-logo.svg';
import { ReactComponent as IconKitchen } from '../../assets/icons/icon-kitchens.svg';
import { ReactComponent as IconSettings } from '../../assets/icons/icon-settings.svg';
import { ReactComponent as IconLocation } from '../../assets/icons/icon-location.svg';
import { ReactComponent as IconClients } from '../../assets/icons/icon-clients.svg';
import { ReactComponent as IconWallet } from '../../assets/icons/icon-wallet.svg';
import { ReactComponent as IconPartners } from '../../assets/icons/icon-partners.svg';
import { ReactComponent as IconSupport } from '../../assets/icons/icon-support.svg';
import { ReactComponent as IconModeration } from '../../assets/icons/icon-moderation.svg';
import { ReactComponent as IconServices } from '../../assets/icons/icon-services.svg';
import { ReactComponent as IconDevmode } from '../../assets/icons/icon-devmode.svg';

import { ReactComponent as IconPhone } from '../../assets/icons/icon-phone.svg';
import { ReactComponent as IconSocialWhatsupLarge } from '../../assets/icons/social/icon-social-whatsup-large.svg';
import { ReactComponent as IconSocialTelegramLarge } from '../../assets/icons/social/icon-social-telegram-large.svg';
import { ReactComponent as IconSocialViberLarge } from '../../assets/icons/social/icon-social-viber-large.svg';
import { ReactComponent as IconSocialVkLarge } from '../../assets/icons/social/icon-social-vk-large.svg';

const { Sider, Header, Content, Footer } = Layout;
const { Title, Text} = Typography;

const modules = [
  {
    key: 'kitchens',
    label: (<Link to='/kitchens' style={{color: 'inherit'}}>Кухни</Link>),
    icon: <IconKitchen/>,
    pages: [
      {
        key: 'dashboard',
        label: (<Link to='/dashboard'>Статистика</Link>),
      },
      {
        key: 'assortment',
        label: (<Link to='/assortment'>Ассортимент</Link>),
      },
      {
        key: 'kitchenfinance',
        label: (<Link to='/kitchenfinance'>Финансы</Link>),
      },
      {
        key: 'reviews',
        label: (<Link to='/reviews'>Отзывы</Link>),
        disabled: true,
      },
      {
        key: 'complaint',
        label: (<Link to='/complaint'>Претензии</Link>),
      },
      {
        key: 'complex',
        label: (<Link to='/complex'>Комплексные товары</Link>),
        disabled: true,
      },
      {
        key: 'assembly',
        label: (<Link to='/assembly'>Сборка товаров</Link>),
        disabled: true,
      },
      {
        key: 'orders',
        label: (<Link to='/orders'>Заказы</Link>),
      },
      {
        key: 'menu',
        label: 'Меню',
        children: [
          {
            key: 'menupublication',
            label: (<Link to='/menupublication'>Публикация меню</Link>),
          },
          {
            key: 'menulayouts',
            label: (<Link to='/menulayouts'>Шаблоны меню</Link>),
          },
          {
            key: 'menumodifiers',
            label: (<Link to='/menumodifiers'>Группы модификаторов</Link>),
          },
        ],
      },
      {
        key: 'kitchensettings',
        label: (<Link to='/kitchensettings'>Настройки</Link>),
      },
      {
        key: 'kitchencontracts',
        label: (<Link to='/kitchencontracts'>Договоры</Link>),
      },
    ],
  },
  {
    key: 'settings',
    label: (<Link to='/settings' style={{color: 'inherit'}}>Настройки</Link>),
    icon: <IconSettings/>,
    pages: [
      {
        key: 'settings/main',
        label: (<Link to='/settings/main'>Основные настройки</Link>),
        disabled: true,
      },
      {
        key: 'settings/stocks',
        label: (<Link to='/settings/stocks'>Акции</Link>),
        disabled: true,
      },
      {
        key: 'settings/notification',
        label: (<Link to='/settings/notification'>Стартовые уведомления</Link>),
        disabled: true,
      },
      {
        key: 'settings/pointsofsale',
        label: (<Link to='/settings/pointsofsale'>Точки продаж</Link>),
        disabled: true,
      },
      {
        key: 'settings/additionalsettings',
        label: (<Link to='/settings/additionalsettings'>Дополнительные настройки</Link>),
        disabled: true,
      },
      {
        key: 'settings/contracts',
        label: (<Link to='/settings/contracts'>Договоры</Link>),
        disabled: true,
      },
      {
        key: 'settings/catalogs',
        label: 'Справочники',
        children: [
          {
            key: 'settings/organizationscatalog',
            label: (<Link to='/settings/organizationscatalog'>Организации</Link>),
          },
          {
            key: 'settings/citiescatalog',
            label: (<Link to='/settings/citiescatalog'>Города</Link>),
          },
        ],
      },
    ],
  },
  {
    key: 'delivery',
    label: (<Link to='/delivery' style={{color: 'inherit'}}>Управление доставкой</Link>),
    icon: <IconLocation/>,
    pages: [
      {
        key: 'delivery/map',
        label: (<Link to='/delivery/map'>Карта с курьерами</Link>),
      },
      {
        key: 'delivery/services',
        label: (<Link to='/delivery/services'>Службы доставки</Link>),
      },
      {
        key: 'delivery/managment',
        label: (<Link to='/delivery/managment'>Управление курьерами</Link>),
        disabled: true,
      },
    ],
  },
  {
    key: 'clients',
    label: (<Link to='/clients' style={{color: 'inherit'}}>Клиенты</Link>),
    icon: <IconClients/>,
    pages: [
      {
        key: 'clients/search',
        label: (<Link to='/clients/search'>Поиск клиентов</Link>),
      },
      {
        key: 'clients/notifications',
        label: (<Link to='/clients/notifications'>Уведомления</Link>),
      },
      {
        key: 'clients/addresses',
        label: (<Link to='/clients/addresses'>Адреса</Link>),
      },
    ],
  },
  {
    key: 'finance',
    label: (<Link to='/finance' style={{color: 'inherit'}}>Маркетинг/Финансы</Link>),
    icon: <IconWallet/>,
    pages: [
      {
        key: 'finance/wallets',
        label: (<Link to='/finance/wallets'>Кошельки</Link>),
      },
      {
        key: 'finance/promocodes',
        label: (<Link to='/finance/promocodes'>Промокоды</Link>),
      },
      {
        key: 'finance/corpclients',
        label: (<Link to='/finance/corpclients'>Корпоративные клиенты</Link>),
      },
    ],
  },
  {
    key: 'partners',
    label: (<Link to='/partners' style={{color: 'inherit'}}>Партнеры</Link>),
    icon: <IconPartners/>,
    pages: [
      {
        key: 'partners/statistics',
        label: (<Link to='/partners/statistics'>Статистика по всем кухням</Link>),
        disabled: true,
      },
      {
        key: 'partners/financialreports',
        label: (<Link to='/partners/financialreports'>Финансовые отчёты</Link>),
        disabled: true,
      },
      {
        key: 'partners/reports',
        label: (<Link to='/partners/reports'>Отчеты</Link>),
        disabled: true,
      },
    ],
  },
  {
    key: 'support',
    label: (<Link to='/support' style={{color: 'inherit'}}>Клиентская поддержка</Link>),
    icon: <IconSupport/>,
    pages: [],
  },
  {
    key: 'moderation',
    label: (<Link to='/moderation' style={{color: 'inherit'}}>Модерация</Link>),
    icon: <IconModeration/>,
    pages: [
      {
        key: 'moderation/moderation',
        label: (<Link to='/moderation/moderation'>Модерация товаров</Link>),
      },
      {
        key: 'moderation/financialsettlement',
        label: (<Link to='/moderation/financialsettlement'>Расчёт с кухнями</Link>),
      },
    ],
  },
  {
    key: 'services',
    label: (<Link to='/services' style={{color: 'inherit'}}>Настройки сервиса</Link>),
    icon: <IconServices/>,
    pages: [
      {
        key: 'services/rightsmanagement',
        label: (<Link to='/services/rightsmanagement'>Управление правами</Link>),
      },
      {
        key: 'services/componentsdirectory',
        label: (<Link to='/services/componentsdirectory'>Справочник компонентов</Link>),
      },
      {
        key: 'services/roleaccess',
        label: (<Link to='/services/roleaccess'>Доступы для ролей</Link>),
      },
      {
        key: 'services/methodsdirectory',
        label: (<Link to='/services/methodsdirectory'>Справочник методов</Link>),
      },
      {
        key: 'services/rolemethods',
        label: (<Link to='/services/rolemethods'>Методы для ролей</Link>),
      },
      {
        key: 'services/entitysettings',
        label: (<Link to='/services/entitysettings'>Настройка сущностей</Link>),
      },
      {
        key: 'services/logs',
        label: 'Логи',
        children: [
          {
            key: 'services/notificationlogs',
            label: (<Link to='/services/notificationlogs'>PUSH-уведомления</Link>),
          },
        ],
      },
    ],
  },
  {
    key: 'documentation',
    label: (<Link to='/documentation' style={{color: 'inherit'}}>Документация</Link>),
    icon: <IconDevmode/>,
    pages: [
      {
        key: 'documentation/adminpaneldocs',
        label: (<Link to='/documentation/adminpaneldocs'>К админ-панели</Link>),
      },
      {
        key: 'documentation/sitedocs',
        label: (<Link to='/documentation/sitedocs'>К сайту</Link>),
      },
      {
        key: 'documentation/courierappdocs',
        label: (<Link to='/documentation/courierappdocs'>К курьерскому приложению</Link>),
      },
    ],
  },
]

const mobileModules = modules.map(module => {
  if (module.pages.length > 0) {
    return {
      ...module,
      children: module.pages
    };
  } else {
    return module;
  }
});

const MainLayout = () => {
  const { isMobile } = useSelector((store) => store.common);
  const { currentModule } = useSelector((store) => store.nav);
  const [pages, setPages] = useState([]);
  const matchingModule = modules.find(module => module.key === currentModule) || [];
  const [isFooterVisible, setIsFooterVisible] = useState(false);
  
  const handleShowFooter = () => {
    setIsFooterVisible(!isFooterVisible);
  };

  useEffect(() => (
    setPages(matchingModule.pages)
  ), [currentModule])

  return (
    <StyledLayout>
      {isMobile ? ( 
        <StyledLayout>
          <StyledHeader>
            <MobileMenu modules={mobileModules}/>
            <HeaderProfileDropdownComponent />
          </StyledHeader>
          <StyledContent>
            <Outlet />
          </StyledContent>
          <StyledFooter>
            <IconLogo/>
          </StyledFooter>
        </StyledLayout>
      ) : (
        <>
          <StyledSider collapsed>
            <WrapperLogo>
              <IconLogo/>
            </WrapperLogo>
            <SiderMenuComponent modules={modules}/>
          </StyledSider>
          <StyledLayout>
            <StyledHeader>
              {matchingModule && <HeaderTitleComponent module={matchingModule} />}
              <HeaderMenuComponent pages={pages} />
              <HeaderProfileDropdownComponent />
            </StyledHeader>
            <StyledContent>
              <Outlet/>
            </StyledContent>
            <StyledFooter show={isFooterVisible}>
              <Title level={5}>Техническая поддержка</Title>
              <Flex gap='16px' style={{height: '30px'}}>
                <Typography.Link href={'https://wa.me/79914590879'} target="_blank" rel="noopener nofollow"><IconSocialWhatsupLarge/></Typography.Link>
                <Typography.Link href={'https://telegram.me/sitiy_support_bot'} target="_blank" rel="noopener nofollow"><IconSocialTelegramLarge/></Typography.Link>
                <Typography.Link href={'viber://pa?chatURI=sitiy'} target="_blank" rel="noopener nofollow"><IconSocialViberLarge/></Typography.Link>
                <Typography.Link href={'https://vk.com/sytofis'} target="_blank" rel="noopener nofollow"><IconSocialVkLarge/></Typography.Link>
                <StyledLink href={'tel:+7-800-200-69-24'}><IconPhone style={{height: '15px'}}/><Text style={{fontSize: '10px'}}>+7-800-200-69-24</Text></StyledLink>
              </Flex>
            </StyledFooter>
            <FloatButton
              icon={<QuestionOutlined/>}
              shape="circle"
              style={{
                right: 30,
                zIndex: 1000,
              }}
              onClick={handleShowFooter}
            />
          </StyledLayout>
        </>
      )}
    </StyledLayout>
  );
};

export default MainLayout;

const StyledLayout = styled(Layout)`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`
const StyledSider = styled(Sider)`
  box-shadow: var(--shadow-contour); 
`
const WrapperLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  height: 45px;
  svg {
    width: 60px;
    fill: #EA394B;
  }
`
const StyledHeader = styled(Header)`
  display: flex;
  align-items: center;
  @media screen and (max-width: 768px) {
    padding: 0 5px;
  }
`
const StyledContent = styled(Content)`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`
const StyledFooter = styled(Footer)`
  position: fixed;
  bottom: ${(props) => props.show ? 0 : '-75px'};
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 4px 20px;
  width: 100%;
  background: white;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
  transition: all .5s ease;
  & svg {
    height: 30px;
  }
`
const StyledLink = styled(Typography.Link)`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 8px;
  padding: 6px 10px;
  width: 100%;
  height: 30px;
  background: #dddddd;
  border-radius: 8px;
  color: #2C2E32;
`

const MobileMenu = ({ modules }) => {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      <Button style={{position: 'fixed', top: '5px', left: '5px', zIndex: '1000', padding: '0 5px', width: 'fit-content', fontSize: '24px', color: '#555555'}} type='text' size='large' onClick={showDrawer}>
        <MenuOutlined />
      </Button>
      <CustomDrawer
        title={
          <>
            <WrapperLogo>
              <IconLogo/>
            </WrapperLogo>
            <Button
              type="text"
              icon={<CloseOutlined style={{fontSize: '25px', color: '#aeaeae'}}/>}
              onClick={onClose}
              style={{ position: 'absolute', top: '20px', right: '20px', zIndex: 1 }}
            />
          </>
        }
        placement="left"
        open={visible}
        closeIcon={false}
        style={{padding: '0 !important'}}
      >
        <SiderMenuComponent modules={modules} onClose={onClose}/>
      </CustomDrawer>
    </>
  );
};

const CustomDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 10px;
  }
`;