import axios from 'axios';
import { store } from './redux/configureStore';
import { setAuth } from './redux/reducers/utils/authReducer';
import { message } from 'antd';

const apiInstance = axios.create();

apiInstance.interceptors.request.use(
  (config) => {
    const baseURL = store.getState().common.apiUrl;
    config.baseURL = baseURL;
    return config;
  },
  (error) => Promise.reject(error)
);

apiInstance.interceptors.response.use(
  (response) => handleResponse(response),
  (error) => handleApiError(error)
);

const handleResponse = (response) => {
  // Костыль для дефолтной роли. Плохо работает индикатор newUser от сервера. Старые роли без дефолтной роли. выдают newUser: false
  if (response.data.data.message && response.data.data.message !== 'Дефолтная роль не создана') {
    response.data.result
    ? message.success(response.data.data.message)
    : message.error(response.data.data.message)
  }

  return response;
};

const handleApiError = (error) => {
  if (error.response) {
    const status = error.response.status;
    switch (status) {
      case 400:
        console.log('Некорректные данные!');
        message.error('Некорректные данные!')
        break;
      case 401:
        console.log('Не авторизован!');
        store.dispatch(setAuth(false));
        localStorage.removeItem('token');
        localStorage.removeItem('session');
        break;
      case 403:
        console.log('Не достаточно прав!');
        message.error('Не достаточно прав!')
        break;
      case 404:
        console.log('Api не найден!');
        message.error('Api не найден!')
        break;
      default:
        console.log('Проблемы на сервере!');
        message.error('Проблемы на сервере!')
    }
  } else {
    console.log('Ошибка сети или другие проблемы!');
    message.error('Ошибка сети или другие проблемы!')
  }
  return Promise.reject(error);
};

export const apiRequest = async (url, data = {}, config = {}) => {
  try {
    const response = await apiInstance({
      method: 'post',
      url,
      data,
      ...config
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default apiInstance;

