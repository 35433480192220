import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';

export const GlobalStyle = createGlobalStyle`
	:root {
		--bg: #ffffff;

		--font-size: 16px;

		--color-accent: #EA394B;
		--color-font: #2C2E32;
		--color-second-font: #AAAAAA;

		--radius-small: 8px;
		--radius-medium: 16px;
		--radius-large: 32px;
		--shadow-contour: 0 0 5px 0 rgba(0, 0, 0, .5);
		--shadow-small: 0 0 10px 3px rgba(0, 0, 0, .10);
		--shadow-medium: 0 0 15px 6px rgba(0, 0, 0, .10);
		--shadow-large: 0 0 20px 9px rgba(0, 0, 0, .10);
	}
	*,
	*::before,
	*::after {
		padding: 0;
		margin: 0;
		border: 0;
		box-sizing: border-box;
	}
	a {
		text-decoration: none;
		cursor: pointer;
	}
	ul,
	ol,
	li {
		list-style: none;
	}
	img {
		vertical-align: top;
	}
	button{
		background: transparent;
		cursor: pointer;
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: inherit;
		font-size: inherit;
	}
	html,
	body {
		height: 100%;
		line-height: 1;
		font-size: var(--font-size);
	}
	@font-face {
		font-family: 'Roboto-Medium';
		src: url('../../public/fonts/Roboto-Medium.ttf');
	}
	html,
	body {
		font-family: 'Roboto-Medium', sans-serif;
	}
	.ant-picker-panels {
		flex-direction: column;
	}
	.ant-picker-cell-disabled::before {
		background: transparent !important;
	}
`;

export const antdToken = {
	fontSize: 16,
	colorPrimary: '#EA394B',
	
	Layout: {
		bodyBg: '#f5f5f5',
		
		headerBg: '#ffffff',
		headerColor: '#EA394B',
		headerHeight: 45,
		headerPadding: '0 30px',

		siderBg: '#ffffff',
		triggerBg: '#ffffff',
		triggerColor: '#000000',

		footerBg: '#ffffff',
	},
}

export const Flex = styled.div`
	display: flex;
	flex-direction: ${(props) => props.column && 'column'};
	flex: ${(props) => props.flex && props.flex};
	justify-content: ${(props) => props.justify && props.justify};
	align-items: ${(props) => props.align && props.align};
	gap: ${(props) => props.gap && props.gap};
	flex-wrap: ${(props) => props.wrap && 'wrap'};
`

export const Page = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1;
	height: 100%;
	overflow: hidden;
`
export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	max-width: 1440px;
	margin: 0 auto;
	padding: 0 15px;
	@media (max-width: 1470px) {
		max-width: 1200px;
	}
	@media (max-width: 1230px) {
		max-width: 1024px;
	}
	@media (max-width: 1054px) {
		max-width: 100%;
	}
`
export const ScrolledCol = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	flex: ${(props) => props.width ? `0 0 ${props.width}` : 1};
	height: 100%;
	max-height: ${(props) => props.height ? props.height : 'calc(100vh - 45px)'};
	overflow-x: hidden;
	overflow-y: auto;
	&::-webkit-scrollbar {
		width: 5px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #f5f5f5;
		border-radius: 5px;
		transition: all 0.3s ease;
	}
	&::-webkit-scrollbar-track {
		background-color: #f5f5f5;
		border: 1px solid #f5f5f5;
	}
	&:hover::-webkit-scrollbar-thumb {
		background-color: #999999;
	}
`

export const VerticalSpace = styled.div`
	display: flex;
	flex-direction: column;
	padding: 120px 0;
`

export const PageHeader = styled(Flex)`

`

export const PageContent = styled(Flex)`
	flex-direction: column;
`

export const Title = styled.div`
	display: flex;
	gap: 15px;
	font-size: ${(props) => props.level === 1
							? '32px'
							: props.level === 2
							? '24px'
							: props.level === 3
							? '20px'
							: props.level === 4
							? '18px'
							: props.level === 5
							? '16px'
							: '32px'};
	font-weight: 600;
	color: ${(props) => props.secondary ? 'var(--color-second-font)' : props.color ? props.color : 'var(--color-font)'};
`

export const Text = styled.p`
	font-size: ${(props) => props.size ? props.size : 'var(--font-size)'};
	color: ${(props) => props.secondary ? 'var(--color-second-font)' : props.color ? props.color : 'var(--color-font)'};
	text-decoration: ${(props) => props.link && 'underline'};
	color: ${(props) => props.link && '#2c5bf7'};
	cursor: ${(props) => props.link && 'pointer'};
`

export const CardSection = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	background: #F9F9F9;
	border: solid 1px #EAEAEA;
	border-radius: var(--radius-large);
	padding: 24px;
	width: 100%;
	overflow: hidden;
`