import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { CheckIcon, ClipIcon } from '../../../../../components/Icons';
import { Flex, Text, Title } from '../../../../../core/styles';
import step1 from '../../../../../image/step-1.png';
import step3 from '../../../../../image/step-3.png';
import step4 from '../../../../../image/step-4.png';
import Card from '../../../../../components/Card';

const NewKitchenSteps = () => {
  return (
    <Wrapper>
        <StyledCard bgimg={step1}>
            <Title level={2}>Заключить договор</Title>
            <CardBody>
                <Contacts>
                    <Flex column flex='1'>
                        <Title style={{marginBottom: '10px'}} level={5}>Заполните реквизиты</Title>
                        <Text secondary size='14px'>Это можно сделать один раз. При изменении, обращайтесь к менеджеру</Text>
                    </Flex>
                </Contacts>
            </CardBody>
            <CardCheck><CheckIcon/></CardCheck>
        </StyledCard>
        <StyledCard>
            <Title level={2}>Заполнить список товаров</Title>
            <CardBody>

            </CardBody>
            <CardCheck><CheckIcon/></CardCheck>
        </StyledCard>
        <StyledCard bgimg={step3}>
            <Title level={2}>Закупить посуду и пакеты</Title>
            <CardBody>
                <Notice color='#DAE9FF'>
                    <div style={{position: 'absolute', top: '-12px', right: '20px'}}><ClipIcon/></div>
                    <p>У нас есть проверенная регламентированная тара и рекомендованная посуда.</p>

                    <a style={{marginTop: '10px'}} href='#'>Почему нельзя использовать любую? <span style={{textDecoration: 'underline'}}>Читать...</span></a>
                </Notice>
            </CardBody>
            <CardCheck><CheckIcon/></CardCheck>
        </StyledCard>
        <StyledCard bgimg={step4}>
            <Title level={2}>Провести тестовый запуск</Title>
            <CardBody>
                <Notice color='#FFE6C1'>
                    <div style={{position: 'absolute', top: '-12px', right: '20px'}}><ClipIcon/></div>
                    Как только вы будете готовы, у вас будут приобретены пакеты и посуда, внесены товары, мы согласуем дату проведения тестового запуска
                </Notice>
            </CardBody>
            <CardCheck><CheckIcon/></CardCheck>
        </StyledCard>
    </Wrapper>
  );
}

export default NewKitchenSteps;

const Wrapper = styled.div`
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    margin: 50px 0;
`
const StyledCard = styled(Card)`
    flex: 1;
    background-image: ${({ bgimg }) => bgimg ? `url(${bgimg})` : 'none'};
    background-position: left bottom;
    background-color: #ffffff;
    background-size: auto 100px;
    background-repeat: no-repeat;
`
const CardBody = styled.div`
    display: flex;
    flex-direction: column;
    margin: 32px 0;
`
const Contacts = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
    padding: 16px;
    background: #F9F9F9;
    border-radius: var(--radius-medium);
`
const Notice = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 16px;
    background: ${(props) => props.color ? props.color : '#F9F9F9'};
    border-radius: var(--radius-medium);
`
const CardCheck = styled.div`
    margin-top: auto;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background: #F9F9F9;
    border-radius: 60px;
    color: #B2B2B2;
`
