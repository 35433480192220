import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sendRequestCitiesGet } from '../../../../redux/reducers/utils/settingsReducers/settingsCatalogsReducer';
import { sendRequestKitchens } from '../../../../redux/reducers/utils/kitchenReducers/kitchenReducer';
import { 
  sendRequestCreateKitchenPlashka,
  sendRequestDeleteKitchenPlashka,
  sendRequestRenameKitchen,
  sendRequestChangeCityKitchen,
  sendRequestGetAllKitchenStatuses,
  sendRequestChangeStatusKitchen,
  sendRequestkitchenSettingsAccessGet,
  sendRequestkitchenSettingsAccessSet,
  sendRequestKitchenNotWorkingGet,
  sendRequestKitchenNotWorkingCreate,
  sendRequestKitchenNotWorkingDelete,
 } from '../../../../redux/reducers/utils/kitchenReducers/kitchenSettingsReducer';
import { Flex, Typography, Divider, Switch, InputNumber, Input } from 'antd';
import styled from 'styled-components';
import Loader from '../../../../components/Loader';
import { Container, Page, ScrolledCol } from '../../../../core/styles';
import KitchenSelectorComponent from '../../components/KitchenSelectorComponent';
import KitchenNOTselectedComponent from '../../components/KitchenNOTselectedComponent';
import KitchenNameComponent from './components/KitchenNameComponent';
import TestingSettingsComponent from './components/TestingSettingsComponent';
import ImageComponent from './components/ImageComponent';
import SoloSelectorComponent from './components/SoloSelectorComponent';
import NotWorkingDatePicker from '../../../../components/NotWorkingDatePicker';
import { checkAccess, checkElementAccess } from '../../../../core/utils';
import SoloInputComponent from './components/SoloInputComponent';
import FormBuilderComponent from './components/FormBuilderComponent';
import AdminCheckList from './components/AdminCheckList';

const { Title, Text } = Typography;

const SettingsPage = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const { accessComponentsForUser } = useSelector((store) => store.services);
  const { cities } = useSelector((store) => store.settingsCatalogs);
  const { selectKitchen } = useSelector((store) => store.kitchen);
  const {
    allKitchenStatuses,
    kitchenSettingsAccess,
    renameKitchenRequestStatus,
    createKitchenPlashkaRequestStatus,
    deleteKitchenPlashkaRequestStatus,
    changeCityKitchenRequestStatus,
    changeStatusKitchenRequestStatus,
    kitchenSettingsAccessSetRequestStatus,
    kitchenNotWorking,
    kitchenNotWorkingCreateRequestStatus,
    kitchenNotWorkingDeleteRequestStatus,
  } = useSelector((store) => store.kitchenSettings);
  const dispatch = useDispatch();
  const isAdmin = checkAccess(accessComponentsForUser, 'moderation')

  useEffect(() => {
    if (isAuth) {
      dispatch(sendRequestCitiesGet({ token: localStorage.getItem('token') }));
      dispatch(sendRequestGetAllKitchenStatuses({ token: localStorage.getItem('token') }));
    }
  }, [isAuth]);

  useEffect(() => {
    if (
      selectKitchen ||
      kitchenSettingsAccessSetRequestStatus === 'resolved'
    ) {
      dispatch(sendRequestkitchenSettingsAccessGet({ token: localStorage.getItem('token'), kitchenId: selectKitchen.id }));
    }
  }, [selectKitchen, kitchenSettingsAccessSetRequestStatus]);

  useEffect(() => {
    if (
      isAdmin && selectKitchen ||
      kitchenNotWorkingCreateRequestStatus === 'resolved' ||
      kitchenNotWorkingDeleteRequestStatus === 'resolved'
    ) {
      dispatch(sendRequestKitchenNotWorkingGet({ token: localStorage.getItem('token'), kitchenId: selectKitchen.id }));
    }
  }, [
    selectKitchen,
    kitchenNotWorkingCreateRequestStatus,
    kitchenNotWorkingDeleteRequestStatus,
  ]);

  useEffect(() => {
    if (
      renameKitchenRequestStatus === 'resolved' ||
      createKitchenPlashkaRequestStatus === 'resolved' ||
      deleteKitchenPlashkaRequestStatus === 'resolved' ||
      changeCityKitchenRequestStatus === 'resolved' ||
      changeStatusKitchenRequestStatus === 'resolved'
    ) {
      dispatch(sendRequestKitchens({ token: localStorage.getItem('token'), offset: 0, limit: 10000 }));
    }
  }, [
    renameKitchenRequestStatus,
    createKitchenPlashkaRequestStatus,
    deleteKitchenPlashkaRequestStatus,
    changeCityKitchenRequestStatus,
    changeStatusKitchenRequestStatus,
  ]);

  const statusOptions = allKitchenStatuses.map((status) => { return {value: status.id, label: status.name} });
  const cityOptions = cities.map((city) => { return {value: city.id, label: city.name} });

  const handleUploadImage = (uploadFile) => {
    const requestData = {
      token: localStorage.getItem('token'),
      kitchenId: selectKitchen.id,
      image: uploadFile,
    }

    dispatch(sendRequestCreateKitchenPlashka(requestData));
  }

  const handleRemoveImage = () => {
    const requestData = {
      token: localStorage.getItem('token'),
      kitchenId: selectKitchen.id,
    }

    dispatch(sendRequestDeleteKitchenPlashka(requestData));
  }

  const handleChangeKitchenName = (name) => {
    const requestData = {
      token: localStorage.getItem('token'),
      kitchenId: selectKitchen.id,
      name: name,
    }

    dispatch(sendRequestRenameKitchen(requestData))
  }

  const handleChangeKitchenCity = (cityId) => {
    const requestData = {
      token: localStorage.getItem('token'),
      kitchenId: selectKitchen.id,
      cityId: cityId,
    }

    dispatch(sendRequestChangeCityKitchen(requestData))
  }

  const handleChangeKitchenStatus = (statusId) => {
    const requestData = {
      token: localStorage.getItem('token'),
      kitchenId: selectKitchen.id,
      statusId: statusId,
    }

    dispatch(sendRequestChangeStatusKitchen(requestData))
  }

  const handleCreateNotWorkingDays = (days, reason) => {
    const getUniqueKey = () => { return crypto.randomUUID(); };

    const requestData = {
      token: localStorage.getItem('token'),
      kitchenId: selectKitchen.id,
      days: days,
      reason: reason,
      uniqueKey: getUniqueKey(),
    }

    console.log(requestData);
    dispatch(sendRequestKitchenNotWorkingCreate(requestData));
  }

  const handleDeleteNotWorkingDays = (uniqueKey) => {
    const requestData = {
      token: localStorage.getItem('token'),
      uniqueKey: uniqueKey,
    }

    console.log(requestData);
    dispatch(sendRequestKitchenNotWorkingDelete(requestData));
  }

  return (
    <Page>
      {/* {(
        kitchenContractsGetRequestStatus === 'pending' ||
        kitchenContractsCreateRequestStatus === 'pending' ||
        kitchenContractsUpdateRequestStatus === 'pending' ||
        kitchenContractsDeleteRequestStatus === 'pending'
      ) && <Loader />} */}

      <ScrolledCol>
        <Container>
          {selectKitchen ?
            <Wrapper>
              <Flex justify='space-between' wrap>
                <div>
                <Flex style={{marginBottom: '20px'}} gap={16} align='center'>
                  <Title style={{margin: '0'}}>Настройки</Title>
                  <KitchenSelectorComponent/>
                </Flex>

                <Flex
                  gap={20}
                  wrap='wrap-reverse'
                >
                  <ImageComponent
                    isAdmin={isAdmin}
                    onUploadImage={handleUploadImage}
                    onRemoveImage={handleRemoveImage}
                  />
                </Flex>
                </div>
                <div>
                  <AdminCheckList/>
                </div>
              </Flex>
              
              <KitchenNameComponent
                isAdmin={isAdmin}
                data={selectKitchen.name}
                onChange={handleChangeKitchenName}
              />

              <SoloSelectorComponent
                isAdmin={isAdmin}
                title='Город'
                data={selectKitchen.cityId}
                options={cityOptions}
                onChange={handleChangeKitchenCity}
              />

              <SoloSelectorComponent
                isAdmin={isAdmin}
                title='Статус'
                data={selectKitchen.status}
                options={statusOptions}
                onChange={handleChangeKitchenStatus}
              />

              <FormBuilderComponent/>

              {isAdmin && 
                <>
                  <Divider orientation="left">Не рабочие дни</Divider>
                  <NotWorkingDatePicker
                    data={kitchenNotWorking}
                    onCreate={handleCreateNotWorkingDays}
                    onDelete={handleDeleteNotWorkingDays}
                  />
                </>
              }
            </Wrapper>
          :
            <KitchenNOTselectedComponent/>
          }
        </Container>
      </ScrolledCol>
    </Page>
  );
};

export default SettingsPage;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1024px;
  margin: 0 auto;
  padding: 40px 20px;
  background: #ffffff;
  border-radius: 24px;
`
