import React, { useState } from 'react';
import { Flex, Tooltip } from 'antd';
import { DownloadOutlined, CheckCircleOutlined, DownOutlined, BarChartOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const DaysStatistics = () => {
    const settings = {
        infinite: false,
        speed: 300,
        slidesToShow: 3.2,
        slidesToScroll: 1,
        centerMode: false,
        centerPadding: '60px',
        autoplay: false,
        arrows: false,
        touchThreshold: 100,
        responsive: [
            {
                breakpoint: 1470,
                settings: {
                    slidesToShow: 2.2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1.2,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <Wrapper>
            <Slider {...settings}>
                <div key={1}>
                    <DaysStatisticsBlock>
                        <DaysStatisticsTitle>Вторник, 10 сен <span>Сегодня</span></DaysStatisticsTitle>
                        <RaceBlock show/>
                        <RaceBlock/>
                        <RaceBlock/>
                    </DaysStatisticsBlock>
                </div>
                <div key={2}>
                    <DaysStatisticsBlock>
                        <DaysStatisticsTitle>Среда, 11 сен <span>Сегодня</span></DaysStatisticsTitle>
                        <RaceBlock show/>
                        <RaceBlock/>
                        <RaceBlock/>
                    </DaysStatisticsBlock>
                </div>
                <div key={3}>
                    <DaysStatisticsBlock>
                        <DaysStatisticsTitle>Четверг, 12 сен <span>Сегодня</span></DaysStatisticsTitle>
                        <RaceBlock show/>
                        <RaceBlock/>
                        <RaceBlock/>
                    </DaysStatisticsBlock>
                </div>
                <div key={4}>
                    <DaysStatisticsBlock>
                        <DaysStatisticsTitle>Пятница, 13 сен <span>Сегодня</span></DaysStatisticsTitle>
                        <RaceBlock show/>
                        <RaceBlock/>
                        <RaceBlock/>
                    </DaysStatisticsBlock>
                </div>
                <div key={5}>
                    <DaysStatisticsBlock>
                        <DaysStatisticsTitle>Суббота, 14 сен <span>Сегодня</span></DaysStatisticsTitle>
                        <RaceBlock show/>
                        <RaceBlock/>
                        <RaceBlock/>
                    </DaysStatisticsBlock>
                </div>
                <div key={6}>
                    <DaysStatisticsBlock>
                        <DaysStatisticsTitle>Воскресенье, 15 сен <span>Сегодня</span></DaysStatisticsTitle>
                        <RaceBlock show/>
                        <RaceBlock/>
                        <RaceBlock/>
                    </DaysStatisticsBlock>
                </div>
            </Slider>
        </Wrapper>
    );
};

export default DaysStatistics;

const RaceBlock = ({show}) => {
    const [statsShow, setStatsShow] = useState(show ? true : false);

    const handleStatsShowClick = () => {
        setStatsShow(!statsShow)
    }

    return (
        <RaceCard>
            <RaceCardHeader>
                <Flex justify='space-between'>
                    <Flex vertical>
                        <Flex>
                            <h2>11:30-12:30</h2>
                            <div style={{margin: '0 20px', color: '#cccccc'}}>|</div>
                            <span>10:15</span>
                        </Flex>
                        <p>Меню опубликовано</p>
                    </Flex>
                    <Tooltip title="Скачать отчёт">
                        <RaceButton><DownloadOutlined color='white'/></RaceButton>
                    </Tooltip>
                </Flex>
            </RaceCardHeader>
                
            <RaceInfo>
                <InfoTitle>
                    <CheckCircleOutlined/>
                    <p>Прием заказов завершен,<br/>распечатайте чеки</p>
                </InfoTitle>
                <InfoButton onClick={handleStatsShowClick}>
                    <DownOutlined style={{transform: statsShow && 'rotate(180deg)', transition: 'all .3s'}}/>
                    <BarChartOutlined/>
                    <p>Отчет о периоде</p>
                </InfoButton>
            </RaceInfo>

            <div style={{height: statsShow ? '150px' : 0, overflow: 'hidden', transition: 'all .3s'}}>
                <RaceStats>
                    <StatsBlock>
                        <h2>Всего<br/>заказали</h2>
                        <p>45</p>
                    </StatsBlock>
                    <StatsBlock>
                        <h2>Новые<br/>позиции</h2>
                        <p>15</p>
                    </StatsBlock>
                    <StatsBlock danger>
                        <h2>Полностью<br/>распроданы</h2>
                        <p>7</p>
                    </StatsBlock>
                    <StatsBlock>
                        <h2>Общая<br/>сумма</h2>
                        <p>10 600 Р</p>
                    </StatsBlock>
                </RaceStats>

                <RaceLikes>
                    <RaceLike>24</RaceLike>
                    <RaceUnlike>10</RaceUnlike>
                </RaceLikes>
            </div>
        </RaceCard>
    )
}

const Wrapper = styled.div`
position: relative;
    display: block;
    width: 100%;
`
const DaysStatisticsBlock = styled.div`
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 25px 20px;
    background: #ffffff;
    border-radius: 32px;
    font-size: 18px;
`
const DaysStatisticsTitle = styled.h2`
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: 20px;
    padding-bottom: 20px;
    border-bottom: solid 1px #eeeeee;
    font-size: 22px;
    font-weight: 500;

    span {
        font-size: 18px;
    }
`
const RaceCard = styled.div`
    display: flex;
    flex-direction: column;
    padding: 25px 20px;
    background: #f8f8f8;
    border: solid 1px #e8e8e8;
    border-radius: 24px;
`
const RaceCardHeader = styled.div`
    display: flex;
    flex-direction: column;

    h2 {
        margin-bottom: 5px;
        font-size: 22px;
        font-weight: 500;
    }
    span {
        font-size: 22px;
        font-weight: 500;
        color: #d8b542
    }
    p{
        font-size: 14px;
        font-weight: 600;
    }
`
const RaceButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    background: linear-gradient(#34c065, #5be697);
    border-radius: 35%;
    cursor: pointer;
`
const RaceInfo = styled.div`
    margin-top: 20px;
    display: flex;
    gap: 50px;
`
const InfoTitle = styled.div`
    display: flex;
    gap: 10px;
    font-size: 14px;
`
const InfoButton = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
`
const RaceStats = styled.div`
    margin-top: 20px;
    display: flex;
    gap: 10px;
`
const StatsBlock = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: ${(props) => props.danger ? '#fbc0c0' : '#ffffff'};
    border: #cccccc;
    border-radius: 10px;
    h2 {
        margin-bottom: 5px;
        font-size: 10px;
        font-weight: 600;
    }
    p {
        font-size: 18px;
        font-weight: 600;
    }
`
const RaceLikes = styled.div`
    margin-top: 10px;
    flex: 1;
    display: flex;
    padding: 10px;
    background-color: #ffffff;
    border: #cccccc;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
`
const RaceLike = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    color: green;
`
const RaceUnlike = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    color: red;
`