import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Page } from '../../core/styles'

import LoginForm from './components/LoginForm';

const AuthPage = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuth) {
      navigate('/kitchens');
    } 
  }, [isAuth]);

  return (
    <Page>
      <LoginForm/>
    </Page>
  );
};

export default AuthPage;
